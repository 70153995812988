import { LGPowerSaveOptions } from './types'

export default {
  getPowerSaveMode (): Promise<LGPowerSaveOptions> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const signage = new Signage()
      signage.getPowerSaveMode(resolve, reject)
    })
  },

  /**
   * @see {@link http://webossignage.developer.lge.com/api/scap-api/scap16/signage/?wos_flag=getPowerSaveMode#getPowerSaveMode}
   */
  setPowerSaveMode (options: LGPowerSaveOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const Signage = window.Signage
      const screen = new Signage()
      screen.setPowerSaveMode(resolve, reject, {
        powerSaveMode: options
      })
    })
  }

}
