<template>
    <button
        type="button"
        :disabled="disabled"
        :class="type"
        class="reach-button focusable"
        @click="callback($event)"
        @mouseover="mouseoverCallback($event)"
        @mouseout="mouseoutCallback">
        <slot></slot>
    </button>
</template>

<script>
export default {
  props: {
    type: String,
    disabled: Boolean
  },
  methods: {
    callback (e) {
      this.$emit('click', e)
    },
    mouseoverCallback (e) {
      this.$emit('over', e)
    },
    mouseoutCallback (e) {
      this.$emit('out', e)
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.reach-button {
    // font-family: 'Lato', 'sans-serif';
    font-family: 'Lato', 'sans-serif', 'Arial', 'Helvetica';
    font-weight: 100;
    font-size: 1.3rem;

    padding: 3px 7px;
    background-color: rgba(0, 0, 0, 0.9);
    border: 1px solid $reach-orange;
    color: white;

    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;

    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -webkit-appearance: button;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    overflow: visible;
    margin: 0;
    outline: 0;

    width: 7rem;
    text-decoration: none;
    text-transform: none;
}

.reach-button:focus {
    border: 1px solid $reach-blue;
    background-color: $reach-orange-light;
}

.reach-button:active, .reach-button:hover {
    border: 1px solid $reach-blue;
    background-color: $reach-orange-light;
}
</style>
