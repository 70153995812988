import { KeyCodes } from '../types'

const keyCodes: KeyCodes = {
  back: [461],
  home: [3, 122],
  menu: [82],
  settings: [82],
  ok: [13],
  enter: [13],
  /**
   * Do nothing on these keys
   */
  ignore: [1]
}

export default keyCodes
