import ILocalStorage from './ILocalStorage'
import ChromeExtensionLocalStorage from './ChromeExtensionLocalStorage'

export default class ChromeExtensionLocalStorageMixin implements ILocalStorage {
  getStorageItem (key: string): Promise<string | number | boolean | object | null> {
    return ChromeExtensionLocalStorage.getStorageItem(key)
  }

  setStorageItem (key: string, value: string | number | boolean | object | null): Promise<void> {
    return ChromeExtensionLocalStorage.setStorageItem(key, value)
  }

  deleteStorageItem (key: string): Promise<void> {
    return ChromeExtensionLocalStorage.deleteStorageItem(key)
  }

  storageKeyExists (key: string): Promise<boolean> {
    return ChromeExtensionLocalStorage.storageKeyExists(key)
  }
}
