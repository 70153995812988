export default {
  getStorageItem (key: string): Promise<string | number | boolean | object | null> {
    return new Promise(function (resolve, reject) {
      try {
        const value: string | null = localStorage.getItem(key)
        resolve(value)
      } catch (error) {
        console.error('REACHLog: Failed to get local storage item.', error)
        reject(error)
      }
    })
  },

  setStorageItem (key: string, value: string | number | boolean | object | null): Promise<void> {
    return new Promise(function (resolve, reject) {
      try {
        if (typeof value === null) {
          value = 'null'
        } else if (typeof value === 'object') {
          value = JSON.stringify(value)
        } else {
          value = value.toString()
        }

        localStorage.setItem(key, value)
        resolve()
      } catch (error) {
        console.error('REACHLog: Failed to set item in local storage.', error)
        reject(error)
      }
    })
  },

  deleteStorageItem (key: string): Promise<void> {
    return new Promise(function (resolve, reject) {
      try {
        localStorage.removeItem(key)
        resolve()
      } catch (error) {
        console.error('REACHLog: Failed to delete entry in local storage.', error)
        reject(error)
      }
    })
  },

  storageKeyExists (key: string): Promise<boolean> {
    return new Promise(function (resolve, reject) {
      try {
        const value = localStorage.getItem(key)
        if (value === null) {
          resolve(false)
        }
        resolve(true)
      } catch (error) {
        console.error('REACHLog: Failed to delete entry in local storage.', error)
        reject(error)
      }
    })
  }
}
