import { KeyCodes } from './types'
import ControllerEvent from './EventNames'

export default class Controller {
  private readonly keyCodes: KeyCodes

  constructor (buttonCodes: KeyCodes) {
    this.keyCodes = buttonCodes
  }

  public initialize (): void {
    document.addEventListener('click', this.listener.bind(this), false)
    document.addEventListener('keydown', this.listener.bind(this), false)
  }

  public emitEvent (event: ControllerEvent): void {
    const buttonEvent = new Event(event)
    document.dispatchEvent(buttonEvent)
  }

  public listener (keyEvent: KeyboardEvent | MouseEvent): void {
    console.log('REACHLog: Button pressed: ', keyEvent)
    let keyCode

    if (keyEvent instanceof KeyboardEvent) {
      keyCode = keyEvent.keyCode
    } else if (keyEvent.which) {
      keyCode = keyEvent.which
    } else {
      throw new Error('Event not recognized.')
    }

    if (this.keyCodes.back.includes(keyCode)) {
      this.emitEvent(ControllerEvent.back)
    } else if (this.keyCodes.home.includes(keyCode)) {
      this.emitEvent(ControllerEvent.home)
    } else if (this.keyCodes.settings.includes(keyCode)) {
      this.emitEvent(ControllerEvent.settings)
    } else if (this.keyCodes.menu.includes(keyCode)) {
      this.emitEvent(ControllerEvent.menu)
    } else {
      // ignore the keys we do not care about
      // throw new Error(`KeyCode ${keyCode} could not be processed`)
    }
  }
}
