/* eslint-disable no-undef */

import { LGPlatform, LGSystemInfo } from './types'

// @ts-ignore
const DeviceInfo = window.DeviceInfo

function getWebOSPlatformInfo (): Promise<LGPlatform> {
  return new Promise((resolve, reject) => {
    try {
      const device = new DeviceInfo()
      device.getPlatformInfo(resolve, reject)
    } catch (error) {
      console.error(error)
      reject(error)
    }
  })
}

export default {
  async getSystemInfo (): Promise<LGSystemInfo> {
    return {
      // isTV: webOS.platform.tv === true,
      // deviceInfo: await getWebOSDeviceInfo(),
      platform: await getWebOSPlatformInfo()
      // appId: webOS.fetchAppId(),
      // appInfo: await getWebOSAppInfo(),
      // appRootPath: webOS.fetchAppRootPath(),
      // systemInfo: webOS.systemInfo(),
      // deviceLGUDID: (await getDeviceId())?.id
    }
  }
}
