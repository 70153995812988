import BrowserDeviceDetector from '../detectors/BrowserDeviceDetector'
import AbstractDevice from '../AbstractDevice'
import ChromeStorage from '@/services/storage/local/ChromeExtensionLocalStorage'
import localStoreVariables from '@/store/localStoreVariables'
import { SystemInfo, DeviceInfo } from '../types'
import Platform from '@/services/platforms/enums/Platform'

export default class ChromeExtensionDeviceMixin extends AbstractDevice {
  async getDeviceInfo (): Promise<DeviceInfo> {
    const deviceInfo = BrowserDeviceDetector.getDeviceInfo()
    deviceInfo.platform = Platform.ChromeExtension
    return deviceInfo
  }

  async getDeviceIdentifier (): Promise<string | null> {
    return ChromeStorage.getStorageItem(localStoreVariables.DEVICE_ID)
      .then(id => {
        // let temp: string | null
        if (typeof id === 'string') {
          return id as string
        } else if (typeof id === null) {
          return id as null
        }
        throw new TypeError('Invalid device id set.')
      })
  }

  async setDeviceId (id: string): Promise<void> {
    return ChromeStorage.setStorageItem(localStoreVariables.DEVICE_ID, id)
  }

  /**
   * @see {@link https://developer.chrome.com/apps/system_cpu}
   */
  async getCPUInfo () {
    return new Promise<chrome.system.cpu.CpuInfo>(function (resolve, reject) {
      try {
        chrome.system.cpu.getInfo(resolve)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * @see {@link https://developer.chrome.com/apps/system_memory}
   */
  async getMemoryInfo () {
    return new Promise<chrome.system.memory.MemoryInfo>(function (resolve, reject) {
      try {
        chrome.system.memory.getInfo(resolve)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * @see {@link https://developer.chrome.com/apps/system_storage}
   */
  async getStorageInfo () {
    return new Promise<chrome.system.storage.StorageUnitInfo[]>(function (resolve, reject) {
      try {
        chrome.system.storage.getInfo(resolve)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * @see {@link https://developer.chrome.com/apps/system_display}
   */
  async getDisplayInfo () {
    return new Promise<chrome.system.display.DisplayInfo[]>(function (resolve, reject) {
      try {
        chrome.system.display.getInfo(resolve)
      } catch (error) {
        reject(error)
      }
    })
  }

  async getSystemInformation (): Promise<SystemInfo> {
    const systemData = await Promise.all([
      this.getDeviceInfo(),
      this.getStorageInfo(),
      this.getMemoryInfo(),
      this.getCPUInfo(),
      this.getDisplayInfo()
    ])

    return {
      hardware: {
        manufacturer: systemData[0].vendor,
        model: systemData[0].model,
        storage: systemData[1],
        memory: systemData[2],
        cpu: systemData[3],
        display: systemData[4]
      },
      os: {
        name: systemData[0].osName,
        // todo: need a better way to include for manufacturers
        manufacturer: systemData[0].osName === 'Windows' ? 'Microsoft' : systemData[0].vendor,
        version: systemData[0].osVersion
      }
    }
  }
}
