import IApp from '../IApp'

export default class ChromePWAAppMixin implements IApp {
  canRebootDevice (): boolean {
    return false
  }

  rebootDevice (): void {
    throw new Error('Method not implemented.')
  }

  canTerminate (): boolean {
    return true
  }

  refreshSigns (): void {
    window.location.reload()
  }

  restartApplication (): void {
    window.location.reload()
  }

  closeApplication (): void {
    window.close()
  }
}
