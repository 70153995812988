
























































































































































// import SettingsService from '@/services/SettingsService'
import Vue from 'vue'
import PlatformFactory from '@/services/platforms/PlatformFactory'
import SwitchButton from '@/components/elements/Switch.vue'
// import { mapGetters } from 'vuex'
import IPlatform from '../../services/platforms/IPlatform'

export default Vue.extend({
  components: { SwitchButton },
  computed: {
    startOnBootSwitchState: function startOnBootSwitchState () {
      return this.startOnBootSwitch === true ? 'ON' : 'OFF'
    },
    startOnIdleSwitchState: function startOnIdleSwitchState () {
      return this.startOnIdleSwitch === true ? 'ON' : 'OFF'
    },
    sleepOnNoContentSwitchState: function sleepOnNoContentSwitchState () {
      return this.sleepOnNoContentSwitch === true ? 'ON' : 'OFF'
    },
    muteSwitchState: function muteSwitchState () {
      return this.muted === true ? 'ON' : 'OFF'
    },
    isLandscape: function isLandscape () {
      return this.orientation?.indexOf('portrait') === -1
    },
    // wakeLock: function wakeLock () {
    //   // return this.wakeLockAcquired === true || this.wakeLockAcquired === 'true' ? 'ON' : 'OFF'
    // },
    persistLogState: function persistLogState () {
      return this.persistLog === true ? 'ON' : 'OFF'
    },
    currentVolume: function currentVolume () {
      return this.muted === true ? 0 : this.volume
    },
    persistLog: {
      get () {
        return this.$store.getters['app/persistLog']
      },
      set (value) {
        this.togglePersistLog(value)
      }
    },
    startOnBootSwitch: {
      get () {
        return this.$store.getters['app/startOnBoot']
      },
      set (value) {
        this.toggleStartOnBoot(value)
      }
    },
    startOnIdleSwitch: {
      get () {
        return this.$store.getters['app/startOnIdle']
      },
      set (value) {
        this.toggleStartOnIdle(value)
      }
    },
    sleepOnNoContentSwitch: {
      get () {
        return this.$store.getters['app/sleepOnNoContent']
      },
      set (value) {
        this.toggleSleepOnNoContent(value)
      }
    },
    muted: {
      get () {
        return this.$store.getters['app/muted']
      },
      set (value) {
        this.toggleMute(value)
      }
    },
    volume: {
      get () {
        return this.$store.getters['app/volume']
      }
    }
  },
  data () {
    return {
      orientation: null,
      canAcquireWakeLock: false,
      wakeLockAcquired: null,
      isVolumeControlVisible: false,
      isOrientationVisible: false,
      canSleepOnNoContent: false,
      canStartOnIdle: false,
      canStartOnBoot: false,
      canRotateOrientation: false
    }
  },
  methods: {
    async getPlatform () {
      const platform = await PlatformFactory.getInstance()
      return platform
    },
    async landscape () {
      const platform: IPlatform = await this.getPlatform()
      platform.landscape()

      this.orientation = 'landscape-primary'
    },
    async portrait () {
      const platform: IPlatform = await this.getPlatform()
      platform.portrait()
      this.orientation = 'portrait-primary'
    },
    async rotate () {
      const platform: IPlatform = await this.getPlatform()
      platform.rotate()
    },
    getScreenOrientation () {

    },
    toggleStartOnBoot (isEnabled: boolean) {
      if (isEnabled) {
        this.$store.dispatch('app/enableStartOnBoot')
      } else {
        this.$store.dispatch('app/disableStartOnBoot')
      }
    },
    toggleStartOnIdle (isEnabled: boolean) {
      if (isEnabled) {
        this.$store.dispatch('app/enableStartOnIdle')
        PlatformFactory
          .getInstance()
          .then(platform => platform.setStartOnIdle())
      } else {
        this.$store.dispatch('app/disableStartOnIdle')
        PlatformFactory
          .getInstance()
          .then(platform => platform.setStartOnIdle())
      }
    },
    toggleSleepOnNoContent (isEnabled: boolean) {
      if (isEnabled) {
        this.$store.dispatch('app/enableSleepOnNoContent')
      } else {
        this.$store.dispatch('app/disableSleepOnNoContent')
      }
    },
    togglePersistLog (isEnabled: boolean) {
      if (isEnabled) {
        this.$store.dispatch('app/enablePersistLog')
      } else {
        this.$store.dispatch('app/disablePersistLog')
      }
    },
    async toggleMute () {
      this.muted = !this.muted
      const platform: IPlatform = await this.getPlatform()
      if (this.muted) {
        platform.mute()
      } else {
        platform.unmute()
      }
    },
    async volumeUp () {
      const platform: IPlatform = await this.getPlatform()
      platform.volumeUp()
    },
    async volumeDown () {
      const platform: IPlatform = await this.getPlatform()
      platform.volumeDown()
    }
  },
  mounted () {
    console.log('REACHLog: Preference mounted.')
    PlatformFactory
      .getInstance()
      .then(async (platform) => {
        this.isVolumeControlVisible = platform.isVolumeControlVisible()
        this.isOrientationVisible = platform.canSetOrientation()
        this.canStartOnBoot = platform.canStartOnBoot()
        this.canStartOnIdle = platform.canStartOnIdle()
        this.canSleepOnNoContent = platform.canSleepOnNoContent()
        if (this.isOrientationVisible) {
          this.canRotateOrientation = platform.canRotate()
          this.orientation = await platform.getScreenOrientation()
        }
      })
  },
  destroyed () {
    console.log('REACHLog: Preference destroyed.')
  }
})
