export default {
  getStorageItem (key: string): Promise<string | number | boolean | object | null> {
    return new Promise(function (resolve, reject) {
      try {
        chrome.storage.local.get([key], result => {
          resolve(result[key])
        })
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  },
  setStorageItem (key: string, value: string | number | boolean | object | null): Promise<void> {
    return new Promise(function (resolve, reject) {
      try {
        const keyValue: Record<string, string | number | boolean | object | null> = {}
        keyValue[key] = value
        chrome.storage.local.set(keyValue, resolve)
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  },
  deleteStorageItem (key: string): Promise<void> {
    return new Promise(function (resolve, reject) {
      try {
        chrome.storage.local.remove(key, resolve)
      } catch (error) {
        console.error('REACHLog: Failed to delete storage item: ' + key)
        reject(error)
      }
    })
  },
  async storageKeyExists (key: string): Promise<boolean> {
    const value = await this.getStorageItem(key)
    if (value === undefined) {
      return true
    }
    return false
  },
  clearAllData (): Promise<void> {
    return new Promise(function (resolve, reject) {
      try {
        chrome.storage.local.clear(resolve)
      } catch (error) {
        console.error('REACHLog: Failed to clear storage data.')
        reject(error)
      }
    })
  }
}
