import ICache from './ICache'
import { SIGNS_BASE_URL } from '@/app/Constants'
import ChromeExtensionLocalStorage from '../storage/local/ChromeExtensionLocalStorage'

export default class ChromeExtensionCacheMixin implements ICache {
  canClearCache (): boolean {
    return true
  }

  canClearAllData (): boolean {
    return true
  }

  /**
   * Removes cache for https://signs.reachcm.com
   */
  clearCache (): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        chrome.browsingData.remove({
          originTypes: {
            unprotectedWeb: true
          },
          // @ts-ignore // because this is available Since Chrome 74.
          origins: [SIGNS_BASE_URL]
        }, {
          appcache: true,
          cache: true,
          cacheStorage: true,
          cookies: true,
          fileSystems: true,
          indexedDB: true,
          localStorage: true,
          pluginData: true,
          webSQL: true
        }, resolve)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * Removes cache for https://signs.reachcm.com and application
   */
  clearAllData (): Promise<void> {
    return Promise.all([
      this.clearApplicationData(),
      ChromeExtensionLocalStorage.clearAllData()
    ]).then(Promise.resolve).catch(console.error)
  }

  private clearApplicationData (): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        chrome.browsingData.remove({
          originTypes: {
            extension: true,
            unprotectedWeb: true
          },
          // @ts-ignore // because this is available Since Chrome 74.
          origins: [
            `chrome-extension://${chrome.runtime.id}/`,
            SIGNS_BASE_URL
          ]
        }, {
          appcache: true,
          cache: true,
          cacheStorage: true,
          cookies: true,
          fileSystems: true,
          indexedDB: true,
          localStorage: true,
          pluginData: true,
          webSQL: true
        }, resolve)
      } catch (error) {
        reject(error)
      }
    })
  }
}
