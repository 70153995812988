















import Vue from 'vue'
import PlatformFactory from '@/services/platforms/PlatformFactory'

export default Vue.extend({
  computed: {
    networkStatus: function networkStatus () {
      return networkStatus ? 'ONLINE' : 'OFFLINE'
    }
  },
  data () {
    return {
      isOnline: null
    }
  },
  methods: {
    async getNetworkStatus () {
      const platform = await PlatformFactory.getInstance()
      this.isOnline = platform.isOnline()
    }
  },
  mounted () {
    console.log('REACHLog: Network mounted.')
    this.getNetworkStatus()
  },
  destroyed () {
    console.log('REACHLog: Network destroyed.')
  }
})
