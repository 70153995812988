













import Vue from 'vue'
// import router from '@/router'
import ApplicationStartupService from '@/services/ApplicationStartupService'
import { mapGetters } from 'vuex'
import Settings from '@/components/views/Settings.vue'
import _ from 'lodash'
import ControllerEvent from './services/controller/EventNames'

export default Vue.extend({
  name: 'App',
  components: {
  },
  computed: {
    ...mapGetters({
      uuid: 'app/playerUuid'
    })
  },
  data: function () {
    return {
      settingsOverlayShow: false as boolean,
      showSettingsButtonInPlatform: false as boolean,
      showSettingsButton: false as boolean,
      testHandler: null
    }
  },
  methods: {
    triggerShowSettingsButton () {
      this.showSettingsButton = true
      this.hideSettingsButtonDebounced()
    },
    hideSettingsButtonDebounced: _.debounce(function () {
      // @ts-ignore
      this.showSettingsButton = false
    }, 7000),
    hideSettingsButton () {
      this.showSettingsButton = false
    },
    settingsHandler () {
      if (this.settingsOverlayShow) {
        this.settingsOverlayShow = false
        this.$modal.hide('Settings')
        return
      }

      this.settingsOverlayShow = true
      this.$modal.show(
        Settings,
        {},
        {
          name: 'Settings',
          adaptive: true,
          width: '100%',
          height: '100%',
          classes: [
            'settings-modal'
          ]
        },
        {
          closed: () => {
            this.settingsOverlayShow = false
            console.info('ReachLog: Settings modal closed.')
          },
          opened: () => {
            console.info('REACHLog: Settings modal opened.')
          }
        }
      )
    }
  },
  mounted () {
    console.info('REACHLog: App mounted.')
    const uuid: string = this.uuid
    ApplicationStartupService.initialize(uuid)
    // todo: this should be inferred from the platform object
    this.showSettingsButtonInPlatform = true

    // Start listening for the ToggleSettings event.
    document.addEventListener(
      ControllerEvent.settings,
      this.settingsHandler.bind(this),
      false
    )

    /**
     * Start listening for the keydown event for enter key
     * This will be useful for players without a pointing device
     * E.g. LG TV can only be controlled with a remote controller
     * Key Code for Enter or OK button in remote controllers is 13
     */
    document.addEventListener('keydown',
      (e: KeyboardEvent) => {
        if (e.keyCode === 13 && !this.settingsOverlayShow) {
          this.settingsHandler()
        }
      },
      false
    )
  },
  destroyed () {
    console.info('REACHLog: App destroyed.')
  }
})
