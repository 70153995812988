import IBuildInfo from '@/services/buildInfo/IBuildInfo'
import { BuildInfo } from '../../types'

class ChromeExtensionBuildInfoMixin implements IBuildInfo {
  async getBuildInfo (): Promise<BuildInfo> {
    const extension: chrome.management.ExtensionInfo = await this.getExtensionInfo()
    return {
      id: extension.id,
      name: extension.name,
      version: extension.version
    }
  }

  private async getExtensionInfo (): Promise<chrome.management.ExtensionInfo> {
    return new Promise((resolve, reject) => {
      try {
        chrome.management.getSelf(resolve)
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default ChromeExtensionBuildInfoMixin
