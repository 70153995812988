import { ILog } from './ILog'
import { LogSeverity } from './types'
import { DateTime } from 'luxon'

export default class Log implements ILog {
  readonly severity: LogSeverity;
  readonly data: unknown[];
  readonly timestamp: number;

  constructor (level: LogSeverity, ...args: unknown[]) {
    this.severity = level
    this.data = args
    this.timestamp = Date.now()
  }

  getSeverity (): LogSeverity {
    return this.severity
  }

  getTimestamp (): number {
    return this.timestamp
  }

  getLocalTime (): string {
    return DateTime.fromMillis(this.timestamp).toJSON()
  }

  serialize (): string {
    return JSON.stringify(
      {
        severity: this.severity,
        data: this.data
      }
    )
  }
}
