
import AbstractDevice from '../AbstractDevice'
import Platform from '@/services/platforms/enums/Platform'
import { DeviceInfo, SystemInfo } from '../types'
import localStoreVariables from '@/store/localStoreVariables'
import WebOSDevice from './WebOSDevice'
import BrowserDeviceDetector from '../detectors/BrowserDeviceDetector'
import store from '@/store'
import BrowserLocalStorage from '@/services/storage/local/BrowserLocalStorage'

export default class WebOSDeviceMixin extends AbstractDevice {
  async getDeviceInfo (): Promise<DeviceInfo> {
    const deviceInfo = await WebOSDevice.getSystemInfo()
    const browserInfo = BrowserDeviceDetector.getDeviceInfo()
    const result: DeviceInfo = {
      platform: Platform.WebOS,
      vendor: 'LG',
      browserPlatform: browserInfo.browserPlatform,
      browserName: browserInfo.browserName,
      browserVersion: browserInfo.browserVersion,
      model: deviceInfo.platform.modelName,
      osName: browserInfo.osName,
      osVersion: browserInfo.osVersion,
      uniqueIdentifier: deviceInfo.platform.serialNumber
    }
    console.log('REACHLog: LG Device: ', result)
    console.log('REACHLog: deviceInfo: ', deviceInfo)
    return result
  }

  async getDeviceIdentifier (): Promise<string | null> {
    const id: string | null = localStorage.getItem(localStoreVariables.DEVICE_ID)
    if (typeof id === 'string') {
      return id as string
    } else if (typeof id === null) {
      return id as null
    }
    throw new TypeError('Invalid device id set.')
  }

  async initializeDeviceId (): Promise<void> {
    let deviceId = await BrowserLocalStorage.getStorageItem(localStoreVariables.DEVICE_ID)

    if (typeof deviceId !== 'string') {
      console.warn('REACHLog: Incorrect value set for device id.')
      deviceId = await this.generateDeviceId()
      if (deviceId) {
        await BrowserLocalStorage.setStorageItem(localStoreVariables.DEVICE_ID, deviceId)
        localStorage.setItem(localStoreVariables.DEVICE_ID, deviceId)
      } else {
        throw new Error('Failed to initialize device id.')
      }
    }

    await store.dispatch('app/setDeviceId', deviceId)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async generateDeviceId (length?: number): Promise<string> {
    const deviceInfo = await WebOSDevice.getSystemInfo()
    return deviceInfo.platform.serialNumber
  }

  async getSystemInformation (): Promise<SystemInfo> {
    const systemData = await this.getDeviceInfo()

    return {
      hardware: {
        manufacturer: systemData.vendor,
        model: systemData.model
      },
      os: {
        name: systemData.osName,
        // todo: need a better way to include for manufacturers
        manufacturer: systemData.vendor,
        version: systemData.osVersion
      }
    }
  }
}
