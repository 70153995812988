import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Register from '@/views/Register.vue'
import Screen from '@/views/Screen.vue'
import About from '@/views/About.vue'
import BrowserRegister from '@/views/BrowserRegister.vue'
// import store from '@/store'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      alias: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      // Route to register device with player
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      // Route to register device with player
      path: '/browserRegister',
      name: 'browserRegister',
      component: BrowserRegister
    },
    {
      // Route to register device with player
      path: '/screen',
      name: 'screen',
      component: Screen
      // beforeEnter: (to, from, next) => {
      //   // Do not load signs iframe if the link is not validated
      //   if (store.getters['signs/validated']) {
      //     next()
      //   } else {
      //     next('register')
      //   }
      // }
    }
  ]
})
