import { Screenshot } from './types'

export default {
  getScreenshotAsData (): Promise<Screenshot> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const signage = new Signage()
      signage.captureScreen(resolve, reject, {
        save: false,
        thumbnail: false,
        // @ts-ignore
        imgResolution: Signage.ImgResolution.FHD
      })
    })
  }
}
