import { createMessenger } from '@/services/utilities/IframeMessengerService'
import IPlatform from '../platforms/IPlatform'
import PlatformFactory from '../platforms/PlatformFactory'
import store from '@/store'
import { SignsMessage } from './types'
// import SignsActions from './SignsActions'
import MessageType from './MessageType'
import ActionType from './ActionType'
import Logger from '@/services/log/browser/console/Logger'

/**
 * Messenger instance
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let messenger: any = null

/**
 * Events that are handled
 * @constant {object}
 */
// const events: {SignsActions?: Array<() => void>} = {}

/**
 * Location of proxy file
 * @constant {string}
 */
const domain = 'https://signs.reachcm.com/v/2.1.0.0/proxy.html'

/**
 * Name of Iframe HtmlNode
 * @constant {string}
 */
const name = 'signsFrame'

/**
 * Post data to child iframe
 * @function
 * @param {object} message
 * @param {object|string|number} data
 */
function postData (message: SignsMessage, data: unknown) {
  messenger.post({
    action: message.action,
    id: message.id,
    data: data
  })
}

/**
 * Post data to child iframe
 * @function
 * @param {object} message
 * @param {object|string|number} data
 */
function postActionResult (message: SignsMessage, data?: unknown) {
  messenger.post({
    action: message.action,
    id: message.id,
    data: data
  })
}

/**
 * Main handler for events coming in from signs iframe
 * @callback SignsIframeMessenger~iframeMessageCallback
 * @param {object} event - Iframe post message event
 * @returns {boolean|*}
 * @throws {Error}
 */
async function childIframeEventHandler (event: { data: SignsMessage }) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const message: SignsMessage = event.data
  /**
     * If data property is not present the message is invalid or
     * the origin is not valid
     * todo: make sure the origin is valid
     */
  if (!message) {
    return false
  }
  console.info('REACHLog: Event received in parent ', message)
  if (!message.action) {
    throw Error('\'action\' should be present in the message.')
  }

  switch (message.action) {
    case 'GET_SCREENSHOT':
      platform
        .getScreenshotAsDataURL()
        .then(data => messenger.post({
          action: message.action,
          id: message.id,
          data: data
        }))
      break
    case 'GET_PLAYER_VERSION':
      messenger.post({
        action: message.action,
        id: message.id,
        data: (await platform.getDeviceInfo()).osVersion
      })
      break
    case 'GET_NAMED_VALUE':
      platform
        .getStorageItem(message.key)
        .then((value) => {
          messenger.post({
            action: message.action,
            id: message.id,
            key: message.key,
            data: value || message.value
          })
        })
      break
    case 'SET_NAMED_VALUE':
      platform
        .setStorageItem(message.key, message.value)
        .then(() => {
          messenger.post({
            action: message.action,
            id: message.id,
            key: message.key,
            data: message.value
          })
        })
      break
    case 'DELETE_NAMED_VALUE':
      platform.deleteStorageItem(message.key)
        .then(() => {
          messenger.post({
            action: message.action,
            id: message.id,
            data: true
          })
        })
      break
    case 'CONFIG_KEY_EXISTS':
      platform.storageKeyExists(message.key)
        .then((value) => {
          messenger.post({
            action: message.action,
            id: message.id,
            key: message.key,
            data: value
          })
        })
      break
    case 'GET_NETWORK_STATUS':
      messenger.post({
        action: message.action,
        id: message.id,
        data: platform.getNetworkStatus()
      })
      break
    case 'GET_DEVICE_INFORMATION':
      messenger.post({
        action: message.action,
        id: message.id,
        data: await platform.getDeviceInfo()
      })
      break

    case 'GET_DATA':
      if (message.type === MessageType.APPLICATION_STATE) {
        postData(message, await platform.getApplicationState())
      } else if (message.type === MessageType.SYSTEM_INFORMATION) {
        postData(message, await platform.getSystemInformation())
      } else if (message.type === MessageType.PLATFORM_LOGS) {
        postData(message, Logger.getAllLogs())
      }
      break
    case 'PLAYER_ACTION':
      if (!message.type) {
        break
      }
      if (message.type.indexOf(ActionType.SET_LOG_LEVEL) !== -1) {
        const level = message.type.substring(14, message.type.length)
        store
          .dispatch('app/setLogLevel', level)
          .then(postActionResult.bind(this, message))
      } else if (message.type === ActionType.ENABLE_PERSISTENT_LOG) {
        store
          .dispatch('app/enablePersistLog')
          .then(postActionResult.bind(this, message))
      } else if (message.type === ActionType.DISABLE_PERSISTENT_LOG) {
        store
          .dispatch('app/disablePersistLog')
          .then(postActionResult.bind(this, message))
      } else if (message.type === 'DELETE_LOGS') {
        Logger.deleteAllLogs()
        postActionResult(message)
        // if (message.type === 'TOGGLE_SETTINGS_OVERLAY') {
      //   SettingsService.dispatchToggleSettingsEvent()
      //     .then(postActionResult.bind(this, message))
      } else if (message.type === 'NO_SCHEDULED_CONTENT') {
        // release wakelock
        // navigate away from /screen
        // allow device to sleep
      } else if (message.type === ActionType.APPLICATION_RESTART) {
        platform.restartApplication()
      }
      break

    default:
      throw Error('\'action\' not recognized.')
  }
}

/**
 * Initialize messenger and addEventHandler for messages
 * @function
 * @returns {SignsIframeMessenger} Module instance
 */
function initialize () {
  messenger = createMessenger(domain, name)
  messenger.addEventListener(childIframeEventHandler)
}

/**
 * Get messenger
 * @function
 * @returns {SignsIframeMessenger}
 */
function get () {
  return messenger
}

/**
 * Adds handler to an action from iframe
 * @function
 * @param {string} action
 * @param {SignsIframeMessenger~iframeMessageCallback} callback
 */
// function addIframeEventListener (action: SignsActions, callback: () => void) {
// if (!events[action]) {
//   events[action] = []
// }

// events[actions].push(callback)
// }

/**
 * Handle messages coming in from signs iframe
 * @module SignsIframeMessenger
 */
export default {
  initialize,
  get
  // addIframeEventListener
}
