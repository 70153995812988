<template>
  <div id="homePage" class="page">
    <div class="home-wrapper">
        <p class="message">
            {{ message }}
        </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Home',
  components: {
  },
  computed: {
    ...mapGetters({
      playerUuid: 'app/playerUuid'
    })
  },
  data () {
    return {
      message: 'Configuring...'
    }
  },
  methods: {
  },
  mounted () {
    console.info('REACHLog:  Home mounted.')
    // If not connected to internet, don't go any further
    // todo: verify offline mode works
    const isConnected = true// NetworkService.isOnline()
    if (isConnected === false) {
      this.message = 'No Internet Connection.'
    } else if (this.playerUuid) {
      // If playeruuid is present but no content stay in home screen
      // SignageService
      //   .isContentScheduled()
      //   .then((hasContent) => {
      //     if (!hasContent) {
      //       this.message = 'No Content Scheduled.'
      //     } else {
      //       StartupService.initialize()
      //     }
      //   })
    } else {
      // If the code reaches here, it means this is new setup
      // StartupService.initialize()
    }
  },
  destroyed () {
    console.info('REACHLog:  Home destroyed.')
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';
#homePage {
    width: 100%;
    height: 100%;
    // text-align: center;
    .home-wrapper {
        .message {
            font-size: 3rem;
            color: $default-white-font-color;
            padding: 1rem;
        }
    }

}
</style>
