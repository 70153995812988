import axios, { AxiosInstance } from 'axios'

const http: AxiosInstance = axios.create({
  baseURL: 'https://api.reachcm.com',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

http.interceptors.request.use(
  function (config: object) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    // console.log('Error Response: ' + JSON.stringify(error.response))
    return Promise.reject(error.response)
  }
)

export default http
