import Log from './Log'
import ErrorLog from './ErrorLog'
import UnhandledRejectionLog from './UnhandledRejectionLog'
import { LogSeverity, LogCallback } from './types'
import { ILog } from './ILog'
import Severity from './Severity'
import store from '@/store'

/* eslint-disable prefer-rest-params */

// const LOG = 'log'
// const INFO = 'info'
// const DEBUG = 'debug'
const WARN = 'warn'
const ERROR = 'error'

const handlers: {[key in LogSeverity]: LogCallback[]} = {
  log: [],
  info: [],
  debug: [],
  warn: [],
  error: []
}

const consoleLog = console.log
const consoleInfo = console.info
const consoleDebug = console.debug
const consoleWarn = console.warn
const consoleError = console.error

const logs: Log[] = []
const unhandledErrors: ILog[] = []
const unhandledRejections: ILog[] = []

let logSeverity: Severity = Severity.WARN
let persistLog = false

function saveLogs (log: Log) {
  persistLog = store.getters['app/persistLog']
  if (persistLog) {
    logs.push(log)
  }
}

function getAllLogs () {
  return {
    logs,
    unhandledErrors,
    unhandledRejections
  }
}

function deleteAllLogs (): void {
  logs.length = 0
  unhandledErrors.length = 0
  unhandledRejections.length = 0
}

function getSerializedLogs (): string {
  return JSON.stringify({
    logs: logs.map((log: Log) => log.serialize()),
    unhandledErrors: unhandledErrors.map((log: ILog) => log.serialize()),
    unhandledRejections: unhandledRejections.map((log: ILog) => log.serialize())
  })
}

function filterLog (severity: LogSeverity): Log[] {
  return logs.filter(log => log.getSeverity() === severity)
}

function getWarnings (): Log[] {
  return filterLog(WARN)
}

function getErrors (): Log[] {
  return filterLog(ERROR)
}

function getUnhandledRejections (): ILog[] {
  return unhandledRejections
}

function getUnhandledErrors (): ILog[] {
  return unhandledErrors
}

const Logger = {
  initialize (): void {
    console.log = Logger.log
    console.debug = Logger.debug
    console.info = Logger.info
    console.warn = Logger.warn
    console.error = Logger.error
    window.onerror = function (message: string | Event, filePath: string | undefined, line: number | undefined, column: number | undefined, error: Error | undefined) {
      unhandledErrors.push(new ErrorLog(error, message, filePath, line, column))
    }

    // @ts-ignore
    window.onunhandledrejection = function (event: PromiseRejectionEvent) {
      unhandledRejections.push(new UnhandledRejectionLog(event))
    }
  },
  getWarnings,
  getErrors,
  getUnhandledRejections,
  getAllLogs,
  deleteAllLogs,
  getSerializedLogs,
  getUnhandledErrors,
  setLogSeverity (severity: Severity) {
    logSeverity = severity
  },
  persistLogs (persist: boolean) {
    persistLog = persist
  },
  addHandler (level: LogSeverity, handler: LogCallback) {
    switch (level) {
      case 'error':
        handlers.error.push(handler)
        break
      case 'warn':
        handlers.warn.push(handler)
        break
      case 'log':
      case 'info':
      case 'debug':
        handlers.log.push(handler)
        break
      default:
        throw new Error(`Severity level '${level}' not supported.`)
    }
  },
  removeHandler (level: LogSeverity, handler: LogCallback) {
    const handlerIndex = handlers[level].findIndex((item) => {
      return item === handler
    })

    handlers[level].splice(handlerIndex, 1)
  },
  log (...args: unknown[]): void {
    consoleLog(...args)
    logSeverity = store.getters['app/logLevel']
    if (logSeverity > Severity.LOG) {
      return
    }
    handlers.log.forEach(handler => handler(...args))
    saveLogs(new Log('log', ...args))
  },
  debug (...args: unknown[]): void {
    consoleDebug(...args)
    logSeverity = store.getters['app/logLevel']
    if (logSeverity > Severity.DEBUG) {
      return
    }
    handlers.log.forEach(handler => handler(...args))
    saveLogs(new Log('debug', ...args))
  },
  info (...args: unknown[]): void {
    consoleInfo(...args)
    logSeverity = store.getters['app/logLevel']
    if (logSeverity > Severity.INFO) {
      return
    }
    handlers.log.forEach(handler => handler(...args))
    saveLogs(new Log('info', ...args))
  },
  warn (...args: unknown[]): void {
    consoleWarn(...args)
    logSeverity = store.getters['app/logLevel']
    if (logSeverity > Severity.WARN) {
      return
    }
    handlers.warn.forEach(handler => handler(...args))
    saveLogs(new Log('warn', ...args))
  },
  error (...args: unknown[]): void {
    consoleError(...args)
    logSeverity = store.getters['app/logLevel']
    if (logSeverity > Severity.ERROR) {
      return
    }
    handlers.error.forEach(handler => handler(...args))
    saveLogs(new Log('error', ...args))
  }
}

export default Logger
