
/**
 * Apply Mixin in typescript
 * Ignoring errors here because the code was lifted from official typescript docs
 * @see {@link https://www.typescriptlang.org/docs/handbook/mixins.html}
 * @export
 * @param {*} derivedCtor
 * @param {any[]} baseCtors
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyMixins (derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
      )
    })
  })
}
