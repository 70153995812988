enum localStoreVariables {
  DEVICE_ID = 'DEVICE_ID',
  PLAYER_UUID = 'PLAYER_UUID',
  LAUNCH_URL = 'LAUNCH_URL',
  START_ON_BOOT = 'START_ON_BOOT',
  START_ON_IDLE = 'START_ON_IDLE',
  SLEEP_ON_NO_CONTENT = 'SLEEP_ON_NO_CONTENT',
  FORCE_START = 'FORCE_START',
  VOLUME = 'VOLUME',
  MUTED = 'MUTED',
  ORIENTATION = 'ORIENTATION',
  LOG_LEVEL = 'LOG_LEVEL',
  PERSIST_LOG = 'PERSIST_LOG'
}

export default localStoreVariables
