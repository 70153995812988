import Http from '@/services/http/Http'

/**
 * Provides basic http functionality
 * @module HttpService
 */
export default {
  get (url: string, param?: object) {
    return Http.get(url, {
      params: param
    })
  },
  post (url: string, param: object) {
    return Http.post(url, param)
  },
  put (url: string, param: object) {
    return Http.put(url, param)
  },
  delete (url: string, param: object) {
    return Http.delete(url, param)
  }
}
