export enum AppStates {
  /**
   * Use this to denote the device does not support recording app states
   */
  DEFAULT= 'DEFAULT',
  /**
   * App in background
   */
  PAUSED = 'PAUSED',

  /**
   * App in foreground
   */
  RESUMED = 'RESUMED'
}
