/**
 * Types of network defined in browsers
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation}
 */
export enum ConnectionType {
  BLUETOOTH = 'BLUETOOTH',
  CELLULAR = 'CELLULAR',
  ETHERNET = 'ETHERNET',
  MIXED = 'MIXED',
  NONE = 'NONE',
  WIFI = 'WIFI',
  WIMAX = 'WIMAX',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN'
}

export enum NetworkStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}
