import HttpService from '@/services/http/HttpService'

/**
 * Perform http calls for Signage data
 * @module Signage
 */

/**
 * Get signage configuration details i.e. displays, schedule, etc.
 * @function
 * @param {string} uuid
 * @returns {Promise.<object>}
 */
function getSignageConfiguration (uuid: string) {
  return HttpService.get(`/signage/configuration/${uuid}`)
}

/**
 * Get signage schedules
 * @function
 * @param {string} uuid
 * @returns {Promise.<object>}
 */
export function getSignageSchedules (uuid: string) {
  return getSignageConfiguration(uuid)
    .then((data) => {
      // @ts-ignore // todo: Figure out types
      return Promise.resolve(data.schedules)
    })
}

/**
 * Get signage displays
 * @function
 * @param {string} uuid
 * @returns {Promise.<object>}
 */
export function getSignageDisplays (uuid: string) {
  return getSignageConfiguration(uuid)
    .then((data) => {
      // @ts-ignore // todo: Figure out types
      return Promise.resolve(data.displays)
    })
}
