import keyCodes from '../browser/BrowserKeyCodes'
import IController from '../IController'
import Controller from '../Controller'

export default class BrowserControllerMixin implements IController {
  private buttonController: Controller | undefined;

  requiresCustomController (): boolean {
    return false
  }

  public initializeButtonListeners (): void {
    this.buttonController = new Controller(keyCodes)
    this.buttonController.initialize()
  }
}
