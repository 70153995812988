import IScreenOrientation from '../IScreenOrientation'
import Orientation from '../Orientation'
import WebOSOrientation from './WebOSOrientation'

export default class WebOSOrientationMixin implements IScreenOrientation {
  canSetOrientation (): boolean {
    return true
  }

  /**
   * Set false if the screen can be set to only landscape primary and portrait primary
   */
  canRotate (): boolean {
    return false
  }

  async getScreenOrientation (): Promise<Orientation> {
    // @ts-ignore
    const Signage = window.Signage
    const orientation = await WebOSOrientation.getOrientation()
    if (orientation === Signage.OsdPortraitMode.OFF) {
      return Orientation.LANDSCAPE_PRIMARY
    } else if (orientation === Signage.OsdPortraitMode.ON ||
      orientation === 'on') {
      return Orientation.PORTRAIT_PRIMARY
    } else {
      throw new Error('Could not get Orientation.')
    }
  }

  async isPortrait (): Promise<boolean> {
    return await this.getScreenOrientation() === Orientation.PORTRAIT_PRIMARY
  }

  async isLandscape (): Promise<boolean> {
    return await this.getScreenOrientation() === Orientation.LANDSCAPE_PRIMARY
  }

  portrait (): Promise<boolean | Error> {
    return WebOSOrientation
      .setPortraitMode()
      .then(() => true)
  }

  landscape (): Promise<boolean | Error> {
    return WebOSOrientation
      .setLandscapeMode()
      .then(() => true)
  }

  rotate (): Promise<Orientation | Error> {
    throw new Error('Method not implemented.')
  }
}
