





































































// import { DeviceManager } from '@/services/DeviceManager'
// import CacheManager from '@/services/CacheManager'
import Vue from 'vue'
import ReachButton from '@/components/elements/ReachButton.vue'
import PlatformFactory from '@/services/platforms/PlatformFactory'

export default Vue.extend({
  components: { ReachButton },
  data () {
    return {
      device: null,
      canClearCache: false,
      canClearAllData: false,
      showWarning: false
    }
  },
  methods: {
    async clearCache () {
      const platform = await PlatformFactory.getInstance()
      platform.clearCache()
    },
    async clearAllData () {
      const platform = await PlatformFactory.getInstance()
      platform.clearAllData()
        .then(platform.restartApplication)
    }
  },
  mounted () {
    console.log('REACHLog: Device mounted.')
    PlatformFactory.getInstance()
      .then(async (platform) => {
        this.device = await platform.getDeviceInfo()
        this.canClearCache = platform.canClearCache()
        this.canClearAllData = platform.canClearAllData()
      })
  },
  destroyed () {
    console.log('REACHLog: Device destroyed.')
  }
})
