async function createTab (rootFilePath: string) {
  return new Promise((resolve, reject) => {
    try {
      window.chrome.tabs.create({
        url: window.chrome.extension.getURL(rootFilePath),
        active: true
      }, resolve)
    } catch (error) {
      reject(error)
    }
  })
}

async function launchExtension () {
  await createTab('index.html')
  window.chrome.windows.update(window.chrome.windows.WINDOW_ID_CURRENT, { state: 'fullscreen' }, () => {
    console.log('REACHLog: Screen set to full screen')
  })
}

export async function openExtension () {
  const extensionId = window.chrome.runtime.id
  window.chrome.tabs.query({}, async (tabs) => {
    let reOpen = true
    // if (tabs.length) {
    for (let i = tabs.length - 1; i >= 0; i--) {
      if (tabs[i]?.url?.includes(`chrome-extension://${extensionId}/`)) {
        // your tab is alive
        reOpen = false
        window.chrome.tabs.update(tabs[i].id as number, {
          active: true // focus it
        }, tab => {
          if (tab?.windowId) {
            window.chrome.windows.update(
              tab.windowId,
              { state: 'fullscreen' },
              () => {
                console.log('REACHLog: Screen set to full screen')
              }
            )
          }
        })
        break
      }
    }

    if (reOpen) { // it didn't find anything, so create it
      await launchExtension()
    }
  })
}
