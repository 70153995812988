import Log from './Log'

export default class ErrorLog extends Log {
  // private message: string;
  // private filePath: string;
  // private line: number;
  // private column: number;
  // private error: Error;
  constructor (error: Error | undefined, message: string | Event, filePath: string | undefined, line: number | undefined, column: number | undefined) {
    super('error', {
      message,
      filePath,
      line,
      column,
      stack: error?.stack
    })
  }
}
