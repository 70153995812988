import IPlatform from './IPlatform'
import Platform from './enums/Platform'
import store from '@/store'
import localStoreVariables from '@/store/localStoreVariables'
import FeatureNotImplementedError from '../app/errors/FeatureNotImplementedError'
import Orientation from '../orientation/Orientation'
import { DeviceInfo, SystemInfo } from '../device/types'
import { NetworkStatus } from '../network/Constants'
import { getRandomString } from '../utilities/util'
import { DEVICE_ID_LENGTH } from './consts'
import { BuildInfo } from '../buildInfo/types'
import { ApplicationState } from '../app/types'
import { APPLICATION_NAME } from '@/app/Constants'

export default abstract class AbstractPlatform implements IPlatform {
  public constructor () {
    this.registerServiceWorker()
  }

  /**
   * Prepare the platform
   */
  protected abstract async initialize(): Promise<IPlatform>;

  public async initializeDeviceId (): Promise<void> {
    let deviceId = await this.getStorageItem(localStoreVariables.DEVICE_ID)

    if (typeof deviceId !== 'string') {
      console.warn('REACHLog: Incorrect value set for device id.')
      deviceId = await this.generateDeviceId(DEVICE_ID_LENGTH)
      if (deviceId) {
        await this.setStorageItem(localStoreVariables.DEVICE_ID, deviceId)
        localStorage.setItem(localStoreVariables.DEVICE_ID, deviceId)
      } else {
        throw new Error('Failed to initialize device id.')
      }
    }

    await store.dispatch('app/setDeviceId', deviceId)
  }

  public async generateDeviceId (length?: number): Promise<string> {
    return getRandomString(length)
  }

  abstract readonly platform: Platform;
  // abstract async getInstance(): Promise<IPlatform>;
  abstract getStorageItem(key: string): Promise<string | number | boolean | object | null>;
  abstract setStorageItem(key: string, value: string | number | boolean | object | null): Promise<void>;
  abstract deleteStorageItem(key: string): Promise<void>;
  abstract storageKeyExists(key: string): Promise<boolean>;

  abstract autoConfigure(deviceInfo: DeviceInfo): void;
  abstract getDeviceInfo(): Promise<DeviceInfo>;
  public async getBuildInfo (): Promise<BuildInfo> {
    const deviceInfo: DeviceInfo = await this.getDeviceInfo()
    return {
      name: APPLICATION_NAME,
      id: deviceInfo.uniqueIdentifier,
      version: '0.1'
    }
  }

  /**
   * If local storage is available,
   * all platforms should store essential information in localStorage
   * i.e DEVICE_ID, PLAYER_UUID
   */
  getDeviceId (): string | null {
    return localStorage.getItem(localStoreVariables.DEVICE_ID)
  };

  abstract isOnline(): boolean;
  abstract getNetworkStatus(): NetworkStatus;

  /**
   * Setting this to protected because only some platforms can
   * implement this. The constructor of the extending class
   * should implement this method if applicable
   */
  registerServiceWorker (): void {
    // ServiceWorker.registerServiceWorker()
    console.warn('REACHLog: Service Worker is not supported by this platform.')
  }

  /**
   * Restart the application
   */
  abstract restartApplication(): void;

  public canTerminate (): boolean {
    return false
  }

  public getScreenshotAsDataURL (): Promise<string> {
    console.warn('REACHLog: Platform does not support screenshots')
    throw new FeatureNotImplementedError('Screenshot feature not implemented.')
  }

  public canStartOnBoot (): boolean {
    console.warn('REACHLog: Start on boot not supported.')
    return false
  }

  public canAcquireWakeLock (): boolean {
    console.warn('REACHLog: Wake Lock not supported.')
    return false
  }

  public requestKeepAwake (): Promise<void|Error> {
    console.warn('REACHLog: Wake Lock not supported.')
    return Promise.reject(new Error('Wake Lock not supported'))
  }

  public releaseKeepAwake (): Promise<void|Error> {
    console.warn('REACHLog: Wake Lock not supported.')
    return Promise.reject(new Error('Wake Lock not supported'))
  }

  public canStartOnIdle (): boolean {
    console.warn('REACHLog: Wake Lock not supported.')
    return false
  }

  public setStartOnIdle (): Promise<void|Error> {
    console.warn('REACHLog: Start on idle not supported.')
    return Promise.reject(new Error('Start on idle not supported.'))
  }

  public unsetStartOnIdle (): Promise<void|Error> {
    console.warn('REACHLog: Start on idle not supported.')
    return Promise.reject(new Error('Start on idle not supported.'))
  }

  public canSleepOnNoContent (): boolean {
    console.warn('REACHLog: Sleep on no content not supported.')
    return false
  }

  public isVolumeControlVisible (): boolean {
    console.warn('REACHLog: Volume controls not implemented.')
    return false
  }

  public mute (): Promise<boolean | Error> {
    console.warn('REACHLog: Volume controls not implemented.')
    return Promise.reject(new Error('Volume controls not implemented.'))
  }

  public unmute (): Promise<boolean | Error> {
    console.warn('REACHLog: Volume controls not implemented.')
    return Promise.reject(new Error('Volume controls not implemented.'))
  }

  public volumeUp (): Promise<number | Error> {
    console.warn('REACHLog: Volume controls not implemented.')
    return Promise.reject(new Error('Volume controls not implemented.'))
  }

  public volumeDown (): Promise<number | Error> {
    console.warn('REACHLog: Volume controls not implemented.')
    return Promise.reject(new Error('Volume controls not implemented.'))
  }

  public canSetOrientation (): boolean {
    console.warn('REACHLog: Screen orientation not implemented.')
    return false
  }

  public canRotate (): boolean {
    console.warn('REACHLog: Screen rotation not implemented.')
    return false
  }

  public async getScreenOrientation (): Promise<Orientation> {
    /**
     * Experimental feature
     * Supports Chrome 38 and up
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation}
     */
    switch (screen.orientation.type) {
      case 'landscape-primary':
        return Orientation.LANDSCAPE_PRIMARY
      case 'landscape-secondary':
        return Orientation.LANDSCAPE_SECONDARY
      case 'portrait-primary':
        return Orientation.PORTRAIT_PRIMARY
      case 'portrait-secondary':
        return Orientation.PORTRAIT_SECONDARY
      default:
        throw new Error('Invalid orientation type.')
    }
  }

  public async isPortrait (): Promise<boolean> {
    const orientation = await this.getScreenOrientation()
    if (orientation.includes('portrait')) {
      return true
    }
    return false
  }

  public async isLandscape (): Promise<boolean> {
    const orientation = await this.getScreenOrientation()
    if (orientation.includes('landscape')) {
      return true
    }
    return false
  }

  public landscape (): Promise<boolean | Error> {
    console.warn('REACHLog: Screen orientation not implemented.')
    return Promise.reject(new Error('Screen orientation not implemented.'))
  }

  public portrait (): Promise<boolean | Error> {
    console.warn('REACHLog: Screen orientation not implemented.')
    return Promise.reject(new Error('Screen orientation not implemented.'))
  }

  public rotate (): Promise<Orientation | Error> {
    console.warn('REACHLog: Screen orientation not implemented.')
    return Promise.reject(new Error('Screen orientation not implemented.'))
  }

  public async getApplicationState (): Promise<ApplicationState> {
    console.warn('REACHLog: `getApplicationState` method not implemented by the platform.')
    return {
      appState: store.getters['app/appState'],
      deviceId: store.getters['app/deviceId'],
      playerUuid: store.getters['app/playerUuid'],
      isOnline: this.isOnline(),
      logLevel: store.getters['app/logLevel'],
      persistLogs: store.getters['app/persistLog'],
      orientation: store.getters['app/orientation'],
      startOnBoot: store.getters['app/startOnBoot'],
      startOnIdle: store.getters['app/startOnIdle'],
      sleepOnNoContent: store.getters['app/sleepOnNoContent'],
      wakeLockAcquired: store.getters['app/wakeLockAcquired'],
      volume: store.getters['app/volume'],
      muted: store.getters['app/muted'],
      streamSchedules: store.getters['app/streamSchedules'],
      build: await this.getBuildInfo(),
      device: await this.getDeviceInfo()
    }
  }

  public async getSystemInformation (): Promise<SystemInfo> {
    console.warn('REACHLog: `getSystemInformation` method not implemented.')
    return {
      hardware: {
        manufacturer: 'unknown',
        model: 'unknown'
      },
      os: {
        name: 'unknown',
        manufacturer: 'unknown',
        version: 'unknown'
      }
    }
  }

  public closeApplication () {
    console.warn('REACHLog: Platform does not implement `closeApplication`. Calling abstract platform method.')
    window.close()
  }

  public canClearCache (): boolean {
    return false
  }

  public canClearAllData (): boolean {
    return false
  }

  public clearCache (): Promise<void> {
    console.warn('REACHLog: Platform does not implement `clearCache`. Calling abstract platform method.')
    throw new FeatureNotImplementedError('Clear cache not implemented.')
  }

  public clearAllData (): Promise<void> {
    console.warn('REACHLog: Platform does not implement `clearAllData`. Calling abstract platform method.')
    throw new FeatureNotImplementedError('Clear all data not implemented.')
  }

  public requiresCustomController (): boolean {
    console.warn('REACHLog: Platform does not implement `requiresCustomController`. Calling abstract platform method.')
    return false
  }

  public initializeButtonListeners (): void {
    console.warn('REACHLog: Platform does not implement `initializeButtonListeners`. Calling abstract platform method.')
    throw new FeatureNotImplementedError('Button listeners not implemented.')
  }
}
