import { SignsState } from './types'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { saveLaunchUrl, initializeSignsStore } from './actions'

const state: SignsState = {
  validated: false,
  launchUrl: null
}

const actions: ActionTree<SignsState, RootState> = {
  saveLaunchUrl,
  initializeSignsStore
}

export default {
  namespaced: true,
  state,
  getters: {
    isSignsValidated: (state: SignsState) => state.validated,
    launchUrl: (state: SignsState) => state.launchUrl
  },
  mutations: {
    setSignsValid (state: SignsState) {
      state.validated = true
    },
    setSignsInvalid (state: SignsState) {
      state.validated = false
    },
    setLaunchUrl (state: SignsState, url: string) {
      state.launchUrl = url
    }
  },
  actions
}
