import IApp from '../IApp'

export default class WebOSAppMixin implements IApp {
  canRebootDevice (): boolean {
    return true
  }

  rebootDevice (): void {
    const options = {
      // @ts-ignore
      powerCommand: Power.PowerCommand.REBOOT
    }
    // @ts-ignore
    const power = new Power()
    power.executePowerCommand(console.info, console.error, options)
  }

  refreshSigns (): void {
    window.location.reload()
  }

  restartApplication (): void {
    // @ts-ignore
    const configuration = new Configuration()
    configuration.restartApplication(console.info, console.error)
  }

  canTerminate (): boolean {
    return false
  }

  closeApplication (): void{
    throw new Error('Platform does not support termination of application.')
  }
}
