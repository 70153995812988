<template>
  <div id="screenPage">
    <reach-iframe
      v-if="isSignsValidated"
      ref="reachIframeComponent"
      :src="launchUrl"
      :load="initialize"
    ></reach-iframe>
    <!-- <reach-iframe
        ref="reachIframeComponent"
        :src="url"
        :load="initialize">
    </reach-iframe>-->
  </div>
</template>

<script>
import Vue from 'vue'
import ReachIframe from '@/components/ReachIframe/ReachIframe.vue'
import { mapGetters } from 'vuex'
import ApplicationStartupService from '@/services/ApplicationStartupService'
import PlatformFactory from '../services/platforms/PlatformFactory'

export default Vue.extend({
  name: 'Screen',
  components: { ReachIframe },
  data () {
    return {
      // url: 'https://reachcm.com/t/?j9nd6xibzi',
      initialize: false
    }
  },
  computed: {
    ...mapGetters({
      launchUrl: 'signs/launchUrl',
      isSignsValidated: 'signs/isSignsValidated',
      playerUuid: 'app/playerUuid'
    })
  },
  methods: {
    onLoad (e) {
      console.info('onLoad ', e)
    },
    onDocumentLoad (e) {
      console.info('onDocumentLoad ', e)
    },
    async reInitialize () {
      /*
       * Since we cannot load signs without player uuid and launch url set,
       * we will try to reinitialize these value and try initializing the application
       * again.
       * ApplicationStartupService will push the registration view if uuid and launch url
       * is absent
       */
      await this.$store.dispatch('signs/initializeSignsStore')
      ApplicationStartupService.initialize(this.playerUuid)
      this.$nextTick(() => {
        if (this.launchUrl) {
          this.initialize = true
        } else {
        // Failed initialization
          console.log('REACHLog: Failed initialization of Signage.')
        }
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.launchUrl) {
        this.initialize = true
      } else {
        this.reInitialize()
      }
    })

    /**
     * Prevents device from going to sleep
     */
    PlatformFactory.getInstance()
      .then(platform => {
        if (platform.canAcquireWakeLock()) {
          platform.requestKeepAwake()
            .then(() => {
              return this.$store.dispatch('app/enableWakeLock')
            }).catch(console.error)
        }
      })
    console.info('REACHLog: Screen mounted.')
  },
  destroyed () {
    /**
     * Allow device to sleep
     */
    PlatformFactory.getInstance()
      .then(platform => {
        if (platform.canAcquireWakeLock()) {
          platform.releaseKeepAwake()
            .then(() => {
              return this.$store.dispatch('app/disableWakeLock')
            }).catch(console.error)
        }
      })
    console.info('REACHLog: Screen destroyed.')
  }
})
</script>

<style lang="scss" scoped>
#screenPage {
  width: 100%;
  height: 100%;
}
.signs-iframe {
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
</style>
