/**
 * Create iframe HTML component
 *
 * @param {string} id
 * @param {onLoadFunction} onLoadFunction Called when iframe is done loading
 * @returns {HTMLIFrameElement}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createIframeElement (id: string, onLoadFunction: ((this: GlobalEventHandlers, ev: Event) => any) | null): HTMLIFrameElement {
  const iframe: HTMLIFrameElement = document.createElement('iframe')
  iframe.frameBorder = '0'
  iframe.id = id
  iframe.name = 'signsFrame'
  iframe.width = '100%'
  iframe.height = '100%'
  iframe.onload = typeof onLoadFunction === 'function' ? onLoadFunction : null
  return iframe
}
