import { applyMixins } from '@/app/typescript/utilities'
import Errors from '@/services/platforms/PlatformError'
import AbstractPlatform from '../AbstractPlatform'
import Platform from '../enums/Platform'
import ChromeNetworkServiceMixin from '@/services/network/ChromeNetworkServiceMixin'
import ChromeExtensionDeviceMixin from '@/services/device/google/ChromeExtensionDeviceMixin'
import ChromeExtensionLocalStorageMixin from '@/services/storage/local/ChromeExtensionLocalStorageMixin'
import ChromeExtensionAppMixin from '@/services/app/browser/ChromeExtensionAppMixin'
import ChromeExtensionAutoConfigureMixin from '@/services/autoConfigure/ChromeExtensionAutoConfigureMixin'
import ChromeExtensionWakeLockMixin from '@/services/wakeLock/ChromeExtensionWakeLockMixin'
import ChromeExtensionScreenshotMixin from '@/services/screenshot/ChromeExtensionScreenshotMixin'
import ChromeExtensionBuildInfoMixin from '@/services/buildInfo/google/chrome/ChromeExtensionBuildInfoMixin'
import ChromeExtensionOrientationMixin from '@/services/orientation/google/ChromeExtensionOrientationMixin'
import ChromeExtensionCacheMixin from '@/services/cache/ChromeExtensionCacheMixin'
import BrowserControllerMixin from '@/services/controller/browser/BrowserControllerMixin'

class ChromeExtension extends AbstractPlatform {
  private static instance: ChromeExtension;
  public platform: Platform = Platform.ChromeExtension;

  private constructor () {
    super()
  }

  /**
   * @throws Error
   */
  public static async getInstance (): Promise<ChromeExtension> {
    if (ChromeExtension.instance) {
      return ChromeExtension.instance
    }

    return ChromeExtension.createInstance()
  }

  private static async createInstance (): Promise<ChromeExtension> {
    try {
      if (!ChromeExtension.instance) {
        ChromeExtension.instance = new ChromeExtension()
        await ChromeExtension.instance.initialize()
      }

      return ChromeExtension.instance
    } catch (error) {
      console.error('REACHLog: ', Errors.PLATFORM_NOT_INSTANTIATED, error)
      throw new Error('Failed to create the platform instance.')
    }
  }

  protected async initialize (): Promise<ChromeExtension> {
    await this.initializeDeviceId()
    return this
  }
}

interface ChromeExtension extends
  ChromeExtensionDeviceMixin,
  ChromeExtensionAutoConfigureMixin,
  ChromeExtensionLocalStorageMixin,
  ChromeNetworkServiceMixin,
  ChromeExtensionAppMixin,
  ChromeExtensionWakeLockMixin,
  ChromeExtensionScreenshotMixin,
  ChromeExtensionBuildInfoMixin,
  ChromeExtensionOrientationMixin,
  ChromeExtensionCacheMixin,
  BrowserControllerMixin {}

applyMixins(ChromeExtension, [
  ChromeExtensionDeviceMixin,
  ChromeExtensionAutoConfigureMixin,
  ChromeExtensionLocalStorageMixin,
  ChromeNetworkServiceMixin,
  ChromeExtensionAppMixin,
  ChromeExtensionWakeLockMixin,
  ChromeExtensionScreenshotMixin,
  ChromeExtensionBuildInfoMixin,
  ChromeExtensionOrientationMixin,
  ChromeExtensionCacheMixin,
  BrowserControllerMixin
])

export default ChromeExtension
