import INetworkService from '@/services/network/INetworkService'
import { NetworkStatus } from '@/services/network/Constants'

/**
 * Get network details in a browser
 */
export default class BrowserNetworkServiceMixin implements INetworkService {
  public getNetworkStatus (): NetworkStatus {
    return navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE
  }

  public isOnline (): boolean {
    return navigator.onLine
  }

  public onStatusChange (): void {
    console.log('Network status changed: ', this.getNetworkStatus())
  }
}
