import ILocalStorage from './ILocalStorage'
import BrowserLocalStorage from './BrowserLocalStorage'

export default class BrowserLocalStorageMixin implements ILocalStorage {
  getStorageItem (key: string): Promise<string | number | boolean | object | null> {
    return BrowserLocalStorage.getStorageItem(key)
  }

  setStorageItem (key: string, value: string | number | boolean | object | null): Promise<void> {
    return BrowserLocalStorage.setStorageItem(key, value)
  }

  deleteStorageItem (key: string): Promise<void> {
    return BrowserLocalStorage.deleteStorageItem(key)
  }

  storageKeyExists (key: string): Promise<boolean> {
    return BrowserLocalStorage.storageKeyExists(key)
  }
}
