import router from '@/router'
// import IPlatform from './platforms/IPlatform'
import PlatformFactory from './platforms/PlatformFactory'
import View from '@/views/View'
import { DeviceInfo } from './device/types'

/**
 * Service which starts the application instance
 * This service should be initialized only after the Platform has completely loaded
 */
export default {
  /**
   * Try to assign uuid if available, if not call auto configure endpoint
   * @function
   */
  async initialize (uuid: string | void): Promise<void> {
    /*
     * if uuid is present the device is registered - route to screen (display signs)
     */
    if (uuid) {
      if (router.currentRoute.name !== View.SCREEN) {
        router.push(View.SCREEN)
      }
    } else {
      const platform = await PlatformFactory.getInstance()
      const deviceInfo: DeviceInfo = await platform.getDeviceInfo()
      deviceInfo.platform = platform.platform

      platform.autoConfigure(deviceInfo)
    }
  }
}
