import { ActionContext } from 'vuex'
import { SignsState } from './types'
import { RootState } from '@/store/types'
import IPlatform from '@/services/platforms/IPlatform'
import PlatformFactory from '@/services/platforms/PlatformFactory'
import localStoreVariables from '@/store/localStoreVariables'

/**
 * Set launch url for signs
 *
 * @param {string} url
 * @returns {Promise}
 */
export async function saveLaunchUrl (context: ActionContext<SignsState, RootState>, url: string) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  await platform.setStorageItem(localStoreVariables.LAUNCH_URL, url)
  context.commit('setLaunchUrl', url)
  context.commit('setSignsValid')
}

/**
 * Initialize values of the store
 */
export async function initializeSignsStore (context: ActionContext<SignsState, RootState>) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    const url = await platform.getStorageItem(localStoreVariables.LAUNCH_URL)
    const uuid = await platform.getStorageItem(localStoreVariables.PLAYER_UUID)
    if (url) {
      context.dispatch('saveLaunchUrl', url)
    } else if (uuid) {
      context.dispatch('saveLaunchUrl', `https://signs.reachcm.com/start.php?id=${uuid}`)
    }
  } catch (error) {
    console.trace(error)
  }
}
