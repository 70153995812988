<template>
  <div class="browser-register-page">
    <div class="browser-register-wrapper">
      <br />
      <div class="row">
        <h2 class="form-label">Enter Player UUID</h2>
      </div>
      <div class="row">
        <form>
          <input
            v-model="userEntry"
            type="text"
            class="form-control form-input"
            placeholder
            required
          />
          <reach-button
            @click="processShortUrl($event)"
            class="btn btn-default btn-register"
            type="button"
            :disabled='userEntry.length < 1'
          >Submit</reach-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import View from './View'
import ReachButton from '@/components/elements/ReachButton'

export default Vue.extend({
  name: 'BrowserRegister',
  components: { ReachButton },
  data: function () {
    return {
      userEntry: ''
    }
  },
  methods: {
    async processShortUrl (event) {
      event.preventDefault()
      await store.dispatch('app/persistPlayerUuid', this.userEntry)
      await store.dispatch(
        'signs/saveLaunchUrl',
        `https://signs.reachcm.com/start.php?id=${this.userEntry}`
      )
      this.$router.push(View.SCREEN)
    }
  }
})
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.browser-register-page {
  width: 100%;
  height: 100%;
  padding: 5%;
  .browser-register-wrapper {
    .form-label {
      font-size: 3rem;
      color: $default-white-font-color;
      padding: 1rem;
    }
    .form-input {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 2px solid #114979;
      width: 80%;
      float: left;
      border-radius: 0;
      font-size: 2em;
      height: 2em;
      margin-top: 10px;
    }
    .message {
      font-size: 3rem;
      color: $default-white-font-color;
      padding: 1rem;

    }
    .btn-register {
      border-radius: 1px;
      height: 3em;
      margin-top: 10px;
    }
  }
}
</style>
