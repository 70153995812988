




































import Vue from 'vue'
import Tab from '@/components/elements/tabs/Tab.vue'
import Tabs from '@/components/elements/tabs/Tabs.vue'
import Device from '@/components/views/Device.vue'
import Preference from '@/components/views/Preference.vue'
import Network from '@/components/views/Network.vue'
import About from '@/components/views/About.vue'
import PlatformFactory from '../../services/platforms/PlatformFactory'
import ControllerEvent from '../../services/controller/EventNames'
import { getRandomString } from '../../services/utilities/util'

export default Vue.extend({
  name: 'Settings',
  components: { Tab, Tabs, Device, Preference, Network, About },
  data () {
    return {
      backPressHandler: null,
      canTerminate: false,
      /**
       * Section that is currently activated for controller navigation
       */
      spatialNavigationSection: null,
      customController: false
    }
  },
  methods: {
    async exitApp () {
      const platform = await PlatformFactory.getInstance()
      platform.closeApplication()
    },
    backButtonHandler () {
      this.$modal.hide('Settings')
    },
    tabClickHandler (event: MouseEvent | KeyboardEvent, tabClass: string) {
      console.log('REACHLog: Tab clicked: ', event, tabClass)
      // @ts-ignore
      const SpatialNavigation = window.SpatialNavigation
      if (!SpatialNavigation) {
        console.warn('REACHLog: SpatialNavigation not loaded')
      }
      if (this.spatialNavigationSection !== null) {
        SpatialNavigation.remove(this.spatialNavigationSection)
      }
      this.spatialNavigationSection = getRandomString(5)
      SpatialNavigation.add({
        selector: `.${tabClass} .focusable`,
        id: this.spatialNavigationSection
      })
    },
    configureRemoteController () {
      /*
      * Focus the first element manually, in case manual spatial navigation is needed
      */
      this.$nextTick(() => {
        const firstMenuItem: HTMLElement | null = document.querySelector('.focusable')
        firstMenuItem && firstMenuItem.focus()

        // @ts-ignore
        const SpatialNavigation = window.SpatialNavigation
        SpatialNavigation.init()

        // This makes menu options navigatable
        SpatialNavigation.add({
          selector: 'a.focusable'
        })

        firstMenuItem && firstMenuItem.click()
      })
    }
  },
  mounted () {
    console.log('REACHLog: Settings mounted.')

    // Back button of the device should close the settings menu
    this.backPressHandler = this.backButtonHandler.bind(this)
    document.addEventListener(ControllerEvent.back, this.backPressHandler, false)

    PlatformFactory
      .getInstance()
      .then(platform => {
        this.canTerminate = platform.canTerminate()
        if (platform.requiresCustomController()) {
          this.customController = true
          this.configureRemoteController()
        }
      })
  },
  destroyed () {
    document.removeEventListener(ControllerEvent.back, this.backPressHandler, false)
    if (this.customController) {
      // @ts-ignore
      window.SpatialNavigation.uninit()
    }
    console.log('REACHLog: Settings destroyed.')
  }
})
