import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker'

/**
 * Plugins and Dependencies
 */
import VModal from 'vue-js-modal'
import 'bootstrap/dist/css/bootstrap.min.css'
import SignsIframeMessengerService from './services/signs/SignsIframeMessengerService'

/**
 * Custom services
 */
// import PlatformFactory from './services/platforms/PlatformFactory'
import Logger from '@/services/log/browser/console/Logger'

/**
 * Modals
 * Settings overlay is created using this modal
 */
Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.config.productionTip = false

/**
 * Extend native console logger
 */
Logger.initialize()

/**
 * Initialization order
 * - Standalone services
 * - Platform
 * - Vuex stores
 * - Vue application
 */
async function start (): Promise<void> {
  // await PlatformFactory.getInstance()

  await Promise.all([
    store.dispatch('app/initializeAppStore'),
    store.dispatch('signs/initializeSignsStore')
  ])

  Logger.setLogSeverity(store.getters['app/logLevel'])

  /**
   * To post and receive messages from iframe
   */
  SignsIframeMessengerService.initialize()

  new Vue({
    router,
    store,
    render: h => h(App),
    mounted () {
      console.log('REACHLog:  Application root instance mounted.')
    }
  }).$mount('#reachapp')
}

start()
