import ICache from '../ICache'
import WebOSCache from './WebOSCache'

export default class WebOSCacheMixin implements ICache {
  canClearCache (): boolean {
    return true
  }

  canClearAllData (): boolean {
    return false
  }

  clearCache (): Promise<void> {
    return WebOSCache.clearCache()
  }

  clearAllData (): Promise<void> {
    throw new Error('Method not implemented.')
  }
}
