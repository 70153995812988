import { DeviceInfo } from './device/types'
import { getAutoConfig } from './http/Registration'

function getAutoConfiguration (deviceInfo: DeviceInfo) {
  if (!deviceInfo.uniqueIdentifier) {
    throw new Error('REACHLog: Device id not initialized. Cannot register the device.')
  }
  return getAutoConfig({
    make: deviceInfo.platform,
    model: deviceInfo.model,
    version: deviceInfo.osVersion,
    // eslint-disable-next-line @typescript-eslint/camelcase
    device_id: deviceInfo.uniqueIdentifier
  })
}

/**
 * Service to handle device registration tasks
 * @module RegistrationService
 */
export default {
  getAutoConfiguration
}
