enum ActionType {
  SET_LOG_LEVEL = 'SET_LOG_LEVEL',
  ENABLE_PERSISTENT_LOG = 'ENABLE_PERSISTENT_LOG',
  DISABLE_PERSISTENT_LOG = 'DISABLE_PERSISTENT_LOG',
  DELETE_LOGS = 'DELETE_LOGS',
  NO_SCHEDULED_CONTENT = 'NO_SCHEDULED_CONTENT',
  TOGGLE_SETTINGS_OVERLAY = 'TOGGLE_SETTINGS_OVERLAY',
  APPLICATION_RESTART = 'APPLICATION_RESTART'
}

export default ActionType
