import HttpService from '@/services/http/HttpService'
import { AutoConfigParameter } from './types'

export function getAutoConfig (data: AutoConfigParameter) {
  return HttpService.get('/player/autocfg', data)
    .then(data => {
      return {
        // @ts-ignore - typescript is inferring data to be Axios object but it is api response - probably because of intercepts
        uuid: data.config.uuid,
        // @ts-ignore
        launchUrl: data.config.launch_url
      }
    })
}
