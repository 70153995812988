
import AbstractDevice from '../AbstractDevice'
import BrowserDeviceDetector from '../detectors/BrowserDeviceDetector'
import Platform from '@/services/platforms/enums/Platform'
import { DeviceInfo } from '../types'
import localStoreVariables from '@/store/localStoreVariables'

export default class ChromePWADeviceMixin extends AbstractDevice {
  async getDeviceInfo (): Promise<DeviceInfo> {
    const deviceInfo = BrowserDeviceDetector.getDeviceInfo()
    deviceInfo.platform = Platform.GoogleChromePWA
    return deviceInfo
  }

  async getDeviceIdentifier (): Promise<string | null> {
    const id: string | null = localStorage.getItem(localStoreVariables.DEVICE_ID)
    if (typeof id === 'string') {
      return id as string
    } else if (typeof id === null) {
      return id as null
    }
    throw new TypeError('Invalid device id set.')
  }
}
