import Log from './Log'

export default class UnhandledRejectionLog extends Log {
  // private message: string;
  // private filePath: string;
  // private line: number;
  // private column: number;
  // private error: Error;
  constructor (event: PromiseRejectionEvent) {
    super('error', {
      reason: event.reason,
      type: event.type,
      stack: event.reason?.stack,
      event: event
    })
  }
}
