import ChromePWADeviceMixin from '@/services/device/google/ChromePWADeviceMixin'
import BrowserLocalStorageMixin from '@/services/storage/local/BrowserLocalStorageMixin'
import ChromeNetworkServiceMixin from '@/services/network/ChromeNetworkServiceMixin'
import Platform from '../enums/Platform'
import ChromePWAAppMixin from '@/services/app/browser/ChromePWAAppMixin'
import AbstractPlatform from '../AbstractPlatform'
import Errors from '@/services/platforms/PlatformError'
import { applyMixins } from '@/app/typescript/utilities'
import ChromeExtensionAutoConfigureMixin from '@/services/autoConfigure/ChromeExtensionAutoConfigureMixin'
import ServiceWorkerMixin from '@/services/serviceWorker/ServiceWorkerMixin'
import BrowserControllerMixin from '@/services/controller/browser/BrowserControllerMixin'

class ChromePWA extends AbstractPlatform {
  private static instance: ChromePWA;
  public platform: Platform = Platform.PWA;

  private constructor () {
    super()
  }

  /**
   * @throws Error
   */
  public static async getInstance (): Promise<ChromePWA> {
    if (ChromePWA.instance) {
      return ChromePWA.instance
    }

    return ChromePWA.createInstance()
  }

  private static async createInstance (): Promise<ChromePWA> {
    try {
      if (!ChromePWA.instance) {
        ChromePWA.instance = new ChromePWA()
        await ChromePWA.instance.initialize()
      }

      return ChromePWA.instance
    } catch (error) {
      console.error('REACHLog: ', Errors.PLATFORM_NOT_INSTANTIATED, error)
      console.log('error: ', error)
      throw new Error('Failed to create the platform instance.')
    }
  }

  protected async initialize (): Promise<ChromePWA> {
    this.initializeDeviceId()
    this.initializeButtonListeners()
    return this
  }
}

/**
 * Using interface and mixins seems to be the best(only?) way of
 * supporting composition in Typescript
 */
interface ChromePWA extends
  ChromePWADeviceMixin,
  ChromeExtensionAutoConfigureMixin,
  BrowserLocalStorageMixin,
  ChromeNetworkServiceMixin,
  ChromePWAAppMixin,
  ServiceWorkerMixin,
  BrowserControllerMixin {}

applyMixins(ChromePWA, [
  ChromePWADeviceMixin,
  ChromeExtensionAutoConfigureMixin,
  BrowserLocalStorageMixin,
  ChromeNetworkServiceMixin,
  ChromePWAAppMixin,
  ServiceWorkerMixin,
  BrowserControllerMixin
])

export default ChromePWA
