import { DeviceInfo } from '../types'
import Platform from '@/services/platforms/enums/Platform'
import BrowserDevice from '@/services/device/detectors/BrowserDevice'
import localStoreVariables from '@/store/localStoreVariables'

export default {
  getDeviceInfo (): DeviceInfo {
    const device = BrowserDevice.init()
    console.log('REACHLog: Device: ', device)
    return {
      vendor: navigator.vendor,
      browserPlatform: navigator.platform,
      browserName: device.browser.name,
      browserVersion: device.browser.version.toString(),
      model: device.browser.version.toString(),
      platform: Platform.Browser,
      osName: device.os.name,
      osVersion: device.os.version.toString(),
      uniqueIdentifier: localStorage.getItem(localStoreVariables.DEVICE_ID)
    }
  }
}
