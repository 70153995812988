import { AppStates } from '@/app/AppStates'
import { AppState } from './types'
import { ActionContext } from 'vuex'
import { RootState } from '@/store/types'
import {
  initializeAppStore,
  persistPlayerUuid,
  updateStreamSchedules,
  updateLastBackgroundFetch,
  setAppStatePaused,
  setAppStateResumed,
  setDeviceId,
  enableStartOnBoot,
  disableStartOnBoot,
  enableStartOnIdle,
  disableStartOnIdle,
  enableSleepOnNoContent,
  disableSleepOnNoContent,
  initializeScreenOrientation,
  enablePersistLog,
  disablePersistLog,
  setLogLevel,
  enableWakeLock,
  disableWakeLock
} from './actions'
import { DateTime } from 'luxon'
import Orientation from '@/services/orientation/Orientation'
import Severity from '@/services/log/browser/console/Severity'

/**
 * Vuex state context
 * @typedef {object} Context
 * @property {object.<string, *>}
 */

const state: AppState = {
  appIdentifier: null,
  deviceId: null,
  playerUuid: null,
  launchUrl: null,
  appState: AppStates.DEFAULT,
  streamSchedules: null,
  loaded: false,
  lastBackgroundFetch: null,

  // Logs
  logLevel: Severity.INFO,
  persistLog: false,

  startOnBoot: false,
  startOnIdle: false,
  sleepOnNoContent: false,
  wakeLockAcquired: false,

  volume: null,
  muted: null,
  orientation: null
}

/**
 * Vuex store
 * @module app
 */
export default {
  namespaced: true,
  state: state,
  getters: {
    appIdentifier: (state: AppState) => state.appIdentifier,
    deviceId: (state: AppState) => state.deviceId,
    playerUuid: (state: AppState) => state.playerUuid,
    appState: (state: AppState) => state.appState,
    loaded: (state: AppState) => state.loaded,
    streamSchedules: (state: AppState) => state.streamSchedules,
    orientation: (state: AppState) => state.orientation,
    logLevel: (state: AppState) => state.logLevel,
    persistLog: (state: AppState) => state.persistLog,
    lastBackgroundFetch: (state: AppState) => state.lastBackgroundFetch,
    wakeLockAcquired: (state: AppState) => state.wakeLockAcquired,
    volume: (state: AppState) => state.volume,
    muted: (state: AppState) => state.muted,
    startOnBoot: (state: AppState) => state.startOnBoot,
    startOnIdle: (state: AppState) => state.startOnIdle,
    sleepOnNoContent: (state: AppState) => state.sleepOnNoContent
  },
  mutations: {
    setAppIdentifier (state: AppState, id: string) {
      state.appIdentifier = id
    },
    setDeviceId (state: AppState, deviceId: string) {
      state.deviceId = deviceId
    },
    setPlayerUuid (state: AppState, uuid: string) {
      state.playerUuid = uuid
    },
    setStreamSchedules (state: AppState, schedules: object[]) {
      state.streamSchedules = schedules
    },
    setAppState (state: AppState, value: AppStates) {
      state.appState = value
    },
    setLastBackgroundFetch (state: AppState, dateTime: DateTime) {
      state.lastBackgroundFetch = dateTime
    },
    setWakeLockAcquired (state: AppState, lock: boolean) {
      state.wakeLockAcquired = lock
    },
    setMuted (state: AppState, muted: boolean | null) {
      state.muted = muted
    },
    setVolume (state: AppState, volume: number | null) {
      state.volume = volume
    },
    setOrientation (state: AppState, orientation: Orientation | null) {
      state.orientation = orientation
    },
    setLogLevel (state: AppState, level: Severity) {
      state.logLevel = level
    },
    setStartOnBoot (state: AppState, value: boolean) {
      state.startOnBoot = value
    },
    setStartOnIdle (state: AppState, value: boolean) {
      state.startOnIdle = value
    },
    setSleepOnNoContent (state: AppState, value: boolean) {
      state.sleepOnNoContent = value
    },
    setPersistLog (state: AppState, value: boolean) {
      state.persistLog = value
    }
  },
  actions: {
    toggleLogToFile: (context: ActionContext<AppState, RootState>) => context.commit('toggleLogToFile'),
    initializeAppStore,
    setDeviceId,
    persistPlayerUuid,
    updateStreamSchedules,
    setAppStatePaused,
    setAppStateResumed,
    updateLastBackgroundFetch,
    enableStartOnBoot,
    disableStartOnBoot,
    enableStartOnIdle,
    disableStartOnIdle,
    enableSleepOnNoContent,
    disableSleepOnNoContent,
    initializeScreenOrientation,
    enablePersistLog,
    disablePersistLog,
    setLogLevel,
    enableWakeLock,
    disableWakeLock
  }
}
