enum ControllerEvent {
  menu = 'menubuttonevent',
  back = 'backbuttonevent',
  settings = 'settingsbuttonevent',
  settingsOpen = 'settingsopen',
  settingsClose = 'settingsclose',
  home = 'homebuttonevent',
}

export default ControllerEvent
