import Vue from 'vue'
import Vuex from 'vuex'
import signs from './modules/signs/index'
import app from './modules/app/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    signs,
    app
  }
})
