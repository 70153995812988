



































import Vue from 'vue'
import PlatformFactory from '@/services/platforms/PlatformFactory'
import IPlatform from '../../services/platforms/IPlatform'
import { DateTime } from 'luxon'
import { BuildInfo } from '../../services/buildInfo/types'

export default Vue.extend({
  computed: {
    formattedInstallDate: function formattedInstallDate () {
      return DateTime.local().toHTTP()
      // return DateTime.fromJSDate(this.installDate).toHTTP()
    }
  },
  data () {
    return {
      id: '',
      name: '',
      version: ''
    }
  },
  methods: {
    getBuildInfo () {
      PlatformFactory
        .getInstance()
        .then((platform: IPlatform) => {
          return platform.getBuildInfo()
        })
        .then((buildInfo: BuildInfo) => {
          this.id = buildInfo.id
          this.name = buildInfo.name
          this.version = buildInfo.version
        })
    }
  },
  mounted () {
    console.log('REACHLog: About mounted.')
    this.getBuildInfo()
  },
  destroyed () {
    console.log('REACHLog: About destroyed.')
  }
})
