import IApp from '../IApp'

export default class ChromeExtensionAppMixin implements IApp {
  canRebootDevice (): boolean {
    return false
  }

  rebootDevice (): void {
    throw new Error('Method not implemented.')
  }

  canTerminate (): boolean {
    return true
  }

  refreshSigns (): void {
    window.location.reload()
  }

  restartApplication (): void {
    window.location.reload()
  }

  closeApplication (): void{
    chrome.tabs.getCurrent((tab) => {
      if (tab?.id) {
        chrome.windows.update(chrome.windows.WINDOW_ID_CURRENT, {
          state: 'normal'
        }, () => {
          // @ts-ignore
          chrome.tabs.remove(tab.id, () => {
            console.info('REACHLog: Application closed.')
          })
        })
      } else {
        window.close()
      }
    })
  }
}
