import Orientation from '../Orientation'
import IScreenOrientation from '../IScreenOrientation'

export default class ChromeExtensionOrientationMixin implements IScreenOrientation {
  portrait (): Promise<boolean | Error> {
    throw new Error('Method not implemented.')
  }

  landscape (): Promise<boolean | Error> {
    throw new Error('Method not implemented.')
  }

  rotate (): Promise<Orientation | Error> {
    throw new Error('Method not implemented.')
  }

  canRotate (): boolean {
    return false
  }

  canSetOrientation (): boolean {
    return false
  }

  public async getScreenOrientation (): Promise<Orientation> {
    /**
     * Experimental feature
     * Supports Chrome 38 and up
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation}
     */
    switch (screen.orientation.type) {
      case 'landscape-primary':
        return Orientation.LANDSCAPE_PRIMARY
      case 'landscape-secondary':
        return Orientation.LANDSCAPE_SECONDARY
      case 'portrait-primary':
        return Orientation.PORTRAIT_PRIMARY
      case 'portrait-secondary':
        return Orientation.PORTRAIT_SECONDARY
      default:
        throw new Error('Invalid orientation type.')
    }
  }

  public async isPortrait (): Promise<boolean> {
    const orientation = await this.getScreenOrientation()
    if (orientation.includes('portrait')) {
      return true
    }
    return false
  }

  public async isLandscape (): Promise<boolean> {
    const orientation = await this.getScreenOrientation()
    if (orientation.includes('landscape')) {
      return true
    }
    return false
  }
}
