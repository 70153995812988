import IPlatform from './IPlatform'
import ChromePWA from '@/services/platforms/google/ChromePWA'
import ChromeExtension from '@/services/platforms/google/ChromeExtension'
import WebOS from '@/services/platforms/lg/WebOS'

let platform: IPlatform

export default {
  async getInstance (): Promise<IPlatform> {
    if (!platform) {
      /*
       * Meta tag value for platform is inserted during the build step
       */
      // @ts-ignore - because cannot read content
      const reachPlatform = document.querySelector('meta[name="platform"]')?.content
      switch (reachPlatform) {
        case 'ChromeExtension':
          platform = await ChromeExtension.getInstance()
          break
        case 'WebOS':
          platform = await WebOS.getInstance()
          break
        case 'PWA':
        default:
          platform = await ChromePWA.getInstance()
      }
    }
    return platform
  }
}
