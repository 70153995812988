import IWakeLock from './IWakeLock'
import store from '@/store'
import { openExtension } from '../platforms/google/extension/helpers'
import { IDLE_INTERVAL } from '../platforms/google/extension/config'

/**
 * @see {@link https://developer.chrome.com/extensions/power}
 */
enum Level {
  DISPLAY = 'display',
  SYSTEM = 'system'
}

export default class ChromeExtensionWakeLockMixin implements IWakeLock {
  canAcquireWakeLock (): boolean {
    return true
  }

  canStartOnIdle (): boolean {
    return true
  }

  canStartOnBoot (): boolean {
    return true
  }

  async requestKeepAwake (): Promise<void|Error> {
    chrome.power.requestKeepAwake(Level.DISPLAY)
  }

  async releaseKeepAwake (): Promise<void|Error> {
    chrome.power.releaseKeepAwake()
  }

  async setStartOnIdle (): Promise<void|Error> {
    try {
      chrome.idle.setDetectionInterval(IDLE_INTERVAL)
      chrome.idle.onStateChanged.addListener(this.startOnIdleCallback)
    } catch (error) {
      return error
    }
  }

  async unsetStartOnIdle (): Promise<void|Error> {
    try {
      chrome.idle.onStateChanged.removeListener(this.startOnIdleCallback)
    } catch (error) {
      return error
    }
  }

  private startOnIdleCallback (state: string): void {
    if (store.getters['app/startOnIdle'] && state === 'idle') {
      openExtension()
    }
  }
}
