import IScreenshot from './IScreenshot'

export default class ChromeExtensionScreenshotMixin implements IScreenshot {
  getScreenshotAsDataURL (): Promise<string> {
    return new Promise(function (resolve, reject) {
      try {
        chrome.tabs.captureVisibleTab(
          chrome.windows.WINDOW_ID_CURRENT,
          {
            quality: 50
          },
          resolve)
      } catch (err) {
        reject(err)
      }
    })
  }
}
