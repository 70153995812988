<template>
  <div id="registerPage" class="page">
    <div class="container-fluid page-wrapper">
      <div class="row page-item">
        <div class="col-sm-5 col-xs-12 text-center logo-section">
          <div class="section-wrapper">
            <img src="../assets/img/logo.png" class="reach-logo" alt="Reach Logo" />
          </div>
        </div>
        <div class="col-sm-7 col-xs-12 setup-steps">
          <div class="device-code-section section-wrapper">
            <h1 class="setup-title">Let's Get Started!</h1>
            <hr class />
            <ul>
              <li class="step">
                <span class="step-number">STEP 1</span> Log into https://build.reachcm.com
              </li>
              <li class="step">
                <span class="step-number">STEP 2</span> Select the device to activate
              </li>
              <li class="step">
                <span class="step-number">STEP 3</span> Enter code into
                <i>Autoconfig Name</i> and save
              </li>
              <li class="step">
                <span class="step-number">STEP 4</span> Or contact support to activate
              </li>
            </ul>
            <span class="app-code text-center">{{ deviceId }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import View from '@/views/View.ts'
import PlatformFactory from '../services/platforms/PlatformFactory'

export default Vue.extend({
  name: View.REGISTER,
  data: function data () {
    return {
      deviceId: ''
    }
  },
  components: {},
  methods: {
    async setDeviceId () {
      const platform = await PlatformFactory.getInstance()
      this.deviceId = platform.getDeviceId()
    }
  },
  mounted () {
    console.info('REACHLog: Register mounted.')
    this.setDeviceId()
    // when loaded try getting registration information every 10 seconds
    // StartupService.setAutoConfigureInterval(10000)
  },
  destroyed () {
    console.info('REACHLog: Register destroyed.')
  }
})
</script>
<style lang="scss" scoped>
#registerPage {
  width: 100%;
  height: 100%;
  padding: 1.5rem;

  .page-wrapper {
    height: 100%;
    width: 100%;
    display: table;
    .page-item {
      // display: table-cell;
      // vertical-align: middle;

      // @media all and (max-width: 768px) and (min-width: 1px) {
      //     .section-wrapper {
      //         display: block;
      //     }
      // }

      // .section-wrapper{
      //     display: table-cell;
      //     vertical-align: middle;
      // }

      .logo-section {
        height: 100%;
        display: table;
        .reach-logo {
          max-width: 100%;
          // width: 16rem;
        }
      }

      .setup-steps {
        color: rgba(255, 255, 255, 0.9);
        text-align: left;
        display: table;
        height: 100%;
        // .setup-title {
        //     font-size: 3rem;
        //     margin: .7rem 0 .7rem 0;
        // }

        // hr {
        //     width: 25%;
        //     border: 2px solid #0858c5;
        //     float: left;
        //     margin: 1rem 0 2rem 0;
        // }

        ul {
          list-style: none;
          padding: 0;
          clear: both;
          margin-bottom: 3rem;
        }

        .step {
          font-size: 1.2rem;
          margin: 1.3rem 0;
        }

        .step-number {
          display: inline-block;
          margin-right: 40px;
          color: rgb(255, 123, 0);
        }

        .app-code {
          // for better readability of the device id
          font-family: "Times New Roman", Times, serif;
        }
      }
    }

    @media all and (min-width: 769px) {
      .page-item {
        display: table-cell;
        vertical-align: middle;
      }

      .section-wrapper {
        display: table-cell;
        vertical-align: middle;
      }

      hr {
        width: 25%;
        border: 2px solid #0858c5;
        float: left;
        margin: 1rem 0 2rem 0;
      }

      .setup-title {
        font-size: 3rem;
        margin: 0.7rem 0 0.7rem 0;
      }

      .app-code {
        display: inline-block;
        border: 1px solid white;
        font-size: 2.5rem;
        padding: 0 1rem;
        min-width: 75%;
      }
    }

    @media all and (max-width: 768px) and (min-width: 1px) {
      .page-item {
        display: block;
      }

      .section-wrapper {
        display: block;
        width: 80%;
        margin: auto;
      }

      .device-code-section {
        margin-top: 2em;
      }

      hr {
        width: 25%;
        border: 2px solid #0858c5;
        margin: 1rem 0 2rem 0;
        margin: auto;
      }

      .setup-title {
        font-size: 3rem;
        margin: 0.7rem 0 0.7rem 0;
        text-align: center;
        margin-bottom: 0.5em;
      }

      .app-code {
        display: inline-block;
        border: 1px solid white;
        font-size: 2.5rem;
        padding: 0 1rem;
        width: 100%;
      }
    }
  }
}
</style>
