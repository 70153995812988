import IController from '../IController'
import keyCodes from './WebOSKeyCodes'
import Controller from '../Controller'

/**
 * @see {@link http://webossignage.developer.lge.com/api/scap-api/scap16/signage/?wos_flag=KeyCode#KeyCode}
 */
export default class WebOSControllerMixin implements IController {
  private buttonController: Controller | undefined;

  public requiresCustomController (): boolean {
    return true
  }

  public initializeButtonListeners (): void {
    this.buttonController = new Controller(keyCodes)
    this.buttonController.initialize()
  }
}
