import IWakeLock from '../IWakeLock'
import WebOSWakeLock from './WebOSWakeLock'

export default class WebOSWakeLockMixin implements IWakeLock {
  canAcquireWakeLock (): boolean {
    return true
  }

  canStartOnIdle (): boolean {
    return false
  }

  canStartOnBoot (): boolean {
    return false
  }

  requestKeepAwake (): Promise<void | Error> {
    // @ts-ignore
    const Signage = window.Signage

    return WebOSWakeLock.setPowerSaveMode({
      // smart energy saving
      ses: false,
      dpmMode: Signage.DpmMode.OFF,
      automaticStandby: Signage.AutomaticStandbyMode.OFF,
      do15MinOff: false
    })
  }

  releaseKeepAwake (): Promise<void | Error> {
    // @ts-ignore
    const Signage = window.Signage

    return WebOSWakeLock.setPowerSaveMode({
      // smart energy saving
      ses: true,
      dpmMode: Signage.DpmMode.OFF,
      automaticStandby: Signage.AutomaticStandbyMode.OFF,
      do15MinOff: true
    })
  }

  setStartOnIdle (): Promise<void | Error> {
    throw new Error('Method not implemented.')
  }

  unsetStartOnIdle (): Promise<void | Error> {
    throw new Error('Method not implemented.')
  }
}
