import { DEVICE_ID_LENGTH } from '../platforms/consts'

/**
 * Get random string of arbitrary length
 * @param {number} length - Length of output string
 * @returns {string}
 */
export function getRandomString (length: number = DEVICE_ID_LENGTH) {
  if (length < 1) {
    throw new RangeError('Length has to be greater than 0.')
  }
  let string = ''
  if (window.crypto) {
    const randomPool: Uint8Array = new Uint8Array(length)
    crypto.getRandomValues(randomPool)
    for (let i = 0; i < randomPool.length; ++i) {
      string += randomPool[i].toString(36)
    }
  } else {
    do {
      string += Math.random().toString(36).substr(2)
    } while (string.length < length)
  }
  string = string.substr(0, length)
  return string
}

/**
 * Convert value to a boolean representation
 */
export function parseBool (value: string | number | boolean | object | null) {
  if (value === null) {
    return false
  }

  return value === 'true' || value === true
}

/**
 * Safe JSON stringify that does not throw on circular reference
 * @see {@link https://github.com/moll/json-stringify-safe/blob/master/stringify.js}
 */

function serializer (
  replacer: ((this: unknown, key: string | unknown, value: unknown) => unknown) | undefined,
  cycleReplacer: (key: string | unknown, value: unknown) => unknown | unknown) {
  const stack: unknown[] = []; const keys: unknown[] = []

  if (cycleReplacer == null) {
    cycleReplacer = function (key: unknown, value: unknown) {
      if (stack[0] === value) {
        return '[Circular ~]'
      }
      return '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']'
    }
  }

  return function (key: string | unknown, value: unknown) {
    if (stack.length > 0) {
      const thisPos = stack.indexOf(this)
      ~thisPos ? stack.splice(thisPos + 1) : stack.push(this)
      ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key)
      if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value)
    } else stack.push(value)

    return replacer == null ? value : replacer.call(this, key, value)
  }
}

export function jsonStringify (
  value: object,
  replacer?: ((this: unknown, key: string | unknown, value: unknown) => unknown),
  space?: string | number | undefined,
  cycleReplacer?: (key: string | unknown, value: unknown) => unknown) {
  // @ts-ignore
  return JSON.stringify(value, serializer(replacer, cycleReplacer), space)
}
