
















import Vue from 'vue'
import { Tab } from './types'

export default Vue.extend({
  data () {
    return {
      tabs: []
    }
  },
  created () {
    /* Typescript cannot infer type here */
    // @ts-ignore
    this.tabs = this.$children
  },
  methods: {
    selectTab (event: MouseEvent | KeyboardEvent, selectedTab: Tab) {
      this.tabs.forEach((tab: Tab) => {
        tab.isActive = (tab.name === selectedTab.name)
      })
      this.$emit('click', event, selectedTab.name.toLowerCase() + '-wrapper')
    }
  }
})
