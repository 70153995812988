import PlatformFactory from '@/services/platforms/PlatformFactory'
import IPlatform from '@/services/platforms/IPlatform'
import localStoreVariables from '@/store/localStoreVariables'
import Orientation from '@/services/orientation/Orientation'
import Severity from '@/services/log/browser/console/Severity'
import { ActionContext } from 'vuex'
import { RootState } from '@/store/types'
import { AppState } from './types'
import { getSignageSchedules } from '@/services/http/Signage'
import { DateTime } from 'luxon'
import { AppStates } from '@/app/AppStates'
import { parseBool } from '@/services/utilities/util'

/**
 * Initialize player uuid
 *
 * @param {ActionContext<AppState, RootState>} context
 */
async function initializePlayerUuid (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  if (context.getters.playerUuid === null) {
    const playerUuid = await platform.getStorageItem(localStoreVariables.PLAYER_UUID) || null

    if (playerUuid) {
      context.commit('setPlayerUuid', playerUuid)
      context.dispatch('updateStreamSchedules')
    }
  }
}

/**
 * Initialize screen orientation
 *
 * @param {ActionContext<AppState, RootState>} context
 */
export async function initializeScreenOrientation (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const orientation: Orientation = await platform.getScreenOrientation()
  context.commit('setOrientation', orientation)
}

/**
 * Set player uuid for the app
 *
 * @param {Context} context
 * @param {string} uuid
 * @throws {Error}
 */
export async function persistPlayerUuid (context: ActionContext<AppState, RootState>, uuid: string) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.PLAYER_UUID, uuid)
    context.commit('setPlayerUuid', uuid)
    /*
    * If a new player uuid is set, we need the new stream schedules too
    */
    context.dispatch('updateStreamSchedules')
  } catch (error) {
    throw new Error('Failed to set player uuid: ' + error.toString())
  }
}

export async function setDeviceId (context: ActionContext<AppState, RootState>, deviceId: string | null) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.DEVICE_ID, deviceId)
    context.commit('setDeviceId', deviceId)
  } catch (error) {
    throw new Error('REACHLog: Failed to set device id: ' + error.toString())
  }
}

async function initializeStartOnBoot (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const startOnBoot = await platform.getStorageItem(localStoreVariables.START_ON_BOOT)
  context.commit('setStartOnBoot', parseBool(startOnBoot))
}

async function initializePersistLogs (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const persistLogs = await platform.getStorageItem(localStoreVariables.PERSIST_LOG)
  context.commit('setPersistLog', parseBool(persistLogs))
}

async function initializeStartOnIdle (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const startOnIdle = await platform.getStorageItem(localStoreVariables.START_ON_IDLE)
  context.commit('setStartOnIdle', parseBool(startOnIdle))
  if (parseBool(startOnIdle) && platform.canStartOnIdle()) {
    platform.setStartOnIdle()
  }
}

async function initializeSleepOnNoContent (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const sleep = await platform.getStorageItem(localStoreVariables.SLEEP_ON_NO_CONTENT)
  context.commit('setSleepOnNoContent', parseBool(sleep))
}

async function initializeLogLevel (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const level = await platform.getStorageItem(localStoreVariables.LOG_LEVEL)
  context.commit('setLogLevel', parseInt(level as string) || Severity.WARN)
}

async function initializeVolume (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const volume = await platform.getStorageItem(localStoreVariables.VOLUME)
  context.commit('setVolume', parseInt(volume as string) || 0)
}

async function initializeMuteState (context: ActionContext<AppState, RootState>) {
  const platform: IPlatform = await PlatformFactory.getInstance()
  const muted = await platform.getStorageItem(localStoreVariables.MUTED)
  context.commit('setMuted', parseBool(muted) || false)
}

/**
 * Set launch url for signs
 *
 * @param {string} url
 * @returns {Promise}
 */
export async function initializeAppStore (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Initializing app store.')

  await Promise.all([
    initializePlayerUuid(context),
    initializeStartOnBoot(context),
    initializePersistLogs(context),
    initializeStartOnIdle(context),
    initializeSleepOnNoContent(context),
    initializeLogLevel(context),
    initializeVolume(context),
    initializeMuteState(context),
    context.dispatch('initializeScreenOrientation'),
    context.dispatch('updateLastBackgroundFetch')
  ]).catch(console.error)
}

/**
 * Get stream schedules
 *
 * @param {Context} context
 * @returns {Promise}
 */
export async function updateStreamSchedules (context: ActionContext<AppState, RootState>): Promise<void|Error> {
  console.info('REACHLog: Updating stream schedule')
  if (context.state.playerUuid) {
    const schedules = await getSignageSchedules(context.state.playerUuid)
    context.commit('setStreamSchedules', schedules)
    return Promise.resolve()
  }
  return new Error('Player uuid is not set.')
}

/**
 * Set last time the app ran background fetch operation
 *
 * @param {Context} context
 * @returns {Promise}
 */
export async function updateLastBackgroundFetch (context: ActionContext<AppState, RootState>) {
  context.commit('setLastBackgroundFetch', DateTime.local())
}

/**
 * App state paused/resumed
 *
 * @private
 * @param {Context} context
 * @param {string} value
 * @returns {Promise}
 */
async function setAppState (context: ActionContext<AppState, RootState>, value: AppStates): Promise<void|Error> {
  try {
    console.log('REACHLog: Setting app state to: ', value)
    context.commit('setAppState', value)
  } catch (error) {
    return error
  }
}

/**
 * Set app state paused
 *
 * @param {Context} context
 * @returns {Promise}
 */
export async function setAppStatePaused (context: ActionContext<AppState, RootState>) {
  return setAppState(context, AppStates.PAUSED)
}

/**
 * Set app state resumed
 *
 * @param {Context} context
 * @returns {Promise}
 */
export async function setAppStateResumed (context: ActionContext<AppState, RootState>) {
  return setAppState(context, AppStates.RESUMED)
}

/**
* Set start on boot
*
* @param {Context} context
* @param {boolean} value
* @throws {Error}
*/
async function setStartOnBoot (context: ActionContext<AppState, RootState>, value: boolean) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.START_ON_BOOT, value)
    context.commit('setStartOnBoot', value)
  } catch (error) {
    console.error('REACHLog: Failed to set start on boot: ', error)
    throw error
  }
}

/**
 * Enable start on boot
 *
 * @param {Context} context
 * @returns {Promise}
 */
export async function enableStartOnBoot (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Enabling start on boot.')
  return setStartOnBoot(context, true)
}

/**
* Disable start on boot
*
* @param {Context} context
* @returns {Promise}
*/
export async function disableStartOnBoot (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Disabling start on boot.')
  return setStartOnBoot(context, false)
}

/**
* Set start on idle value
*
* @param {Context} context
* @param {boolean} value
* @throws {Error}
*/
async function setStartOnIdle (context: ActionContext<AppState, RootState>, value: boolean) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.START_ON_IDLE, value)
    context.commit('setStartOnIdle', value)
  } catch (error) {
    console.error('REACHLog: Failed to set start on idle: ', error)
    throw error
  }
}

/**
* Enable start on idle
*
* @param {Context} context
* @returns {Promise}
*/
export async function enableStartOnIdle (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Enabling start on idle.')
  return setStartOnIdle(context, true)
}

/**
* Disable start on idle
*
* @param {Context} context
* @returns {Promise}
*/
export async function disableStartOnIdle (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Disabling start on idle.')
  return setStartOnIdle(context, false)
}

/**
* Set sleep on idle value
*
* @param {Context} context
* @param {boolean} value
* @throws {Error}
*/
async function setSleepOnNoContent (context: ActionContext<AppState, RootState>, value: boolean) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.SLEEP_ON_NO_CONTENT, value)
    context.commit('setSleepOnNoContent', value)
  } catch (error) {
    console.error('REACHLog: Failed to set sleep on idle: ', error)
    throw error
  }
}

/**
* Enable sleep on idle
*
* @param {Context} context
* @returns {Promise}
*/
export async function enableSleepOnNoContent (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Enabling sleep on idle.')
  return setSleepOnNoContent(context, true)
}

/**
* Disable sleep on idle
*
* @param {Context} context
* @returns {Promise}
*/
export async function disableSleepOnNoContent (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Disabling sleep on idle.')
  return setSleepOnNoContent(context, false)
}

/**
* Set if device is allowed to sleep or not (wake lock)
*
* @param {Context} context
* @param {boolean} value
* @throws {Error}
*/
async function setWakeLockAcquired (context: ActionContext<AppState, RootState>, value: boolean) {
  try {
    context.commit('setWakeLockAcquired', value)
  } catch (error) {
    console.error('REACHLog: Failed to set wake lock: ', error)
    throw error
  }
}

/**
* Set wake lock value to true
*
* @param {Context} context
* @returns {Promise}
*/
export async function enableWakeLock (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Enabling wake lock.')
  return setWakeLockAcquired(context, true)
}

/**
* Set wake lock value to false
*
* @param {Context} context
* @returns {Promise}
*/
export async function disableWakeLock (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Disabling wake lock.')
  return setWakeLockAcquired(context, false)
}

export async function setPersistLog (context: ActionContext<AppState, RootState>, value: boolean) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.PERSIST_LOG, value)
    context.commit('setPersistLog', value)
  } catch (error) {
    console.error('REACHLog: Failed to enable save logs: ', error)
    throw error
  }
}

export async function setLogLevel (context: ActionContext<AppState, RootState>, severity: 'INFO'|'WARN'|'ERROR'|'DEBUG') {
  try {
    let level: Severity
    switch (severity) {
      case 'INFO':
        level = Severity.INFO
        break
      case 'WARN':
        level = Severity.WARN
        break
      case 'ERROR':
        level = Severity.ERROR
        break
      case 'DEBUG':
        level = Severity.DEBUG
        break
      default:
        level = Severity.INFO
    }
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.LOG_LEVEL, level)
    context.commit('setLogLevel', level)
  } catch (error) {
    console.error('REACHLog: Failed to enable save logs: ', error)
    throw error
  }
}

export async function enablePersistLog (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Enabling save logs.')
  return setPersistLog(context, true)
}

export async function disablePersistLog (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Disabling save logs.')
  return setPersistLog(context, false)
}

/**
* Set if device is muted or not
*
* @param {Context} context
* @param {boolean} value
* @throws {Error}
*/
async function setMuteState (context: ActionContext<AppState, RootState>, value: boolean) {
  try {
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.MUTED, value)
    context.commit('setMuted', value)
  } catch (error) {
    console.error('REACHLog: Failed to set mute state.', error)
    throw error
  }
}

/**
* Set application to muted
*
* @param {Context} context
* @returns {Promise}
*/
export async function mute (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Muting application.')
  return setMuteState(context, true)
}

/**
* Set application to not muted
*
* @param {Context} context
* @returns {Promise}
*/
export async function unmute (context: ActionContext<AppState, RootState>) {
  console.info('REACHLog: Unmuting application.')
  return setMuteState(context, false)
}

/**
* Set volume of the device
*
* @param {Context} context
* @param {number} value - 1-10
*/
export async function setVolume (context: ActionContext<AppState, RootState>, value: number) {
  try {
    console.info('REACHLog: Setting volume to: ' + value)
    const platform: IPlatform = await PlatformFactory.getInstance()
    await platform.setStorageItem(localStoreVariables.VOLUME, value)
    context.commit('setVolume', value)
  } catch (error) {
    console.error('REACHLog: Failed to set volume.', error)
    throw error
  }
}
