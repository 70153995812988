import IAutoConfigure from '@/services/autoConfigure/IAutoConfigure'
import router from '@/router'
import View from '@/views/View'
import { DeviceInfo } from '../device/types'
import DeviceRegistrationService from '../DeviceRegistrationService'
import store from '@/store'

class BrowserAutoConfigureMixin implements IAutoConfigure {
  autoConfigure (deviceInfo: DeviceInfo): void {
    if (router.currentRoute.name !== View.REGISTER) {
      router.push(View.REGISTER)
    }

    DeviceRegistrationService
      .getAutoConfiguration(deviceInfo)
      .then(async (data) => {
        const uuid: string = data.uuid
        const launchUrl: string = data.launchUrl

        await store.dispatch('app/persistPlayerUuid', uuid)
        await store.dispatch('signs/saveLaunchUrl', launchUrl)

        router.push('screen')
      })
      .catch((error) => {
      /**
       * This means that we have no record of this device
       * so route to registration page
       */
        if (error) {
          console.error('REACHLog: Device registration failed.')
          console.debug('REACHLog: Device registration failed: ', error)
        }

        if ((error &&
              error.data &&
              error.data.message &&
              error.data.message.code === 'OBJECT_NOT_FOUND') || error?.data?.message?.code === 'ILLEGAL_PARAMETER') {
          setTimeout(this.autoConfigure.bind(this, deviceInfo), 10000)
          if (router.currentRoute.name !== 'register') {
            router.push('register')
          }
        }
      })
  }
}

export default BrowserAutoConfigureMixin
