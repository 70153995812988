import { applyMixins } from '@/app/typescript/utilities'
import AbstractPlatform from '../AbstractPlatform'
import Platform from '../enums/Platform'
import Errors from '@/services/platforms/PlatformError'
import BrowserLocalStorageMixin from '@/services/storage/local/BrowserLocalStorageMixin'
// import ServiceWorkerMixin from '@/services/serviceWorker/ServiceWorkerMixin'
import WebOSControllerMixin from '@/services/controller/lg/WebOSControllerMixin'
import WebOSDeviceMixin from '@/services/device/lg/WebOSDeviceMixin'
import BrowserAutoConfigureMixin from '@/services/autoConfigure/BrowserAutoConfigureMixin'
import BrowserNetworkServiceMixin from '@/services/network/BrowserNetworkServiceMixin'
import WebOSAppMixin from '@/services/app/lg/WebOSAppMixin'
import WebOSCacheMixin from '@/services/cache/lg/WebOSCacheMixin'
import WebOSScreenshotMixin from '@/services/screenshot/lg/WebOSScreenshotMixin'
import WebOSOrientationMixin from '@/services/orientation/lg/WebOSOrientationMixin'
import WebOSWakeLockMixin from '@/services/wakeLock/lg/WebOSWakeLockMixin'

class WebOS extends AbstractPlatform {
  private static instance: WebOS;
  public platform: Platform = Platform.WebOS;

  private constructor () {
    super()
  }

  /**
   * @throws Error
   */
  public static async getInstance (): Promise<WebOS> {
    if (WebOS.instance) {
      return WebOS.instance
    }

    return WebOS.createInstance()
  }

  private static async createInstance (): Promise<WebOS> {
    try {
      if (!WebOS.instance) {
        WebOS.instance = new WebOS()
        await WebOS.instance.initialize()
      }

      return WebOS.instance
    } catch (error) {
      console.error('REACHLog: ', Errors.PLATFORM_NOT_INSTANTIATED, error)
      console.log('error: ', error)
      throw new Error('Failed to create the platform instance.')
    }
  }

  protected async initialize (): Promise<WebOS> {
    await this.initializeDeviceId()
    if (this.requiresCustomController()) {
      await this.initializeButtonListeners()
    }
    return this
  }
}

interface WebOS extends
  BrowserAutoConfigureMixin,
  BrowserLocalStorageMixin,
  BrowserNetworkServiceMixin,
  WebOSAppMixin,
  // ServiceWorkerMixin,
  WebOSDeviceMixin,
  WebOSControllerMixin,
  WebOSCacheMixin,
  WebOSScreenshotMixin,
  WebOSOrientationMixin,
  WebOSWakeLockMixin {}

applyMixins(WebOS, [
  BrowserAutoConfigureMixin,
  BrowserLocalStorageMixin,
  BrowserNetworkServiceMixin,
  WebOSAppMixin,
  // ServiceWorkerMixin,
  WebOSDeviceMixin,
  WebOSControllerMixin,
  WebOSCacheMixin,
  WebOSScreenshotMixin,
  WebOSOrientationMixin,
  WebOSWakeLockMixin
])

export default WebOS
