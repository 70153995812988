enum Platform {
  Browser = 'Browser',
  PWA = 'PWA',
  WebOS = 'WebOS',
  /**
   * Chrome Browser
   */
  GoogleChrome = 'GoogleChrome',

  /**
   * Progressive Web Applications in Chrome OS
   */
  GoogleChromePWA = 'GoogleChromePWA',

  /**
   * Google Chrome Extension
   */
  ChromeExtension = 'ChromeExtension'
}

export default Platform
