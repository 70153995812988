import { NameVersion, BrowserDevice } from './types'
export default {
  options: [],
  header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor],
  dataOS: [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ],
  dataBrowser: [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ],
  init: function (): BrowserDevice {
    const agent = this.header.join(' ')
    const os: NameVersion = this.matchItem(agent, this.dataOS)
    const browser: NameVersion = this.matchItem(agent, this.dataBrowser)

    return { os, browser }
  },
  matchItem: function (string: string, data: {name: string; value: string; version: string}[]) {
    let i = 0
    let j = 0
    let regex: RegExp
    let regexv: RegExp
    let match: boolean
    let matches: string | RegExpMatchArray | null
    let version: string
    let actualMatch: string | string[]

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i')
      match = regex.test(string)
      if (match) {
        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
        matches = string.match(regexv)
        version = ''
        actualMatch = ''
        if (matches && matches[1]) {
          if (matches[1]) {
            actualMatch = matches[1]
          }
        }
        if (actualMatch) {
          actualMatch = actualMatch.split(/[._]+/)
          for (j = 0; j < actualMatch.length; j += 1) {
            if (j === 0) {
              version += actualMatch[j] + '.'
            } else {
              version += actualMatch[j]
            }
          }
        } else {
          version = '0'
        }
        return {
          name: data[i].name,
          version: parseFloat(version)
        }
      }
    }
    return { name: 'unknown', version: 0 }
  }
}
