/**
 * @see https://github.com/ternarylabs/porthole
 */

/**
 * Create a communication bridge between iframe with domain sent in parameter
 *
 * @param {string} domain
 * @param {string} name
 * @returns {object} Porthole messenger instance
 */
export function createMessenger (domain: string, name: string) {
  // @ts-ignore
  return new Porthole.WindowProxy(domain, name)
}
