import IScreenshot from '../IScreenshot'
import WebOSScreenshot from './WebOSScreenshot'
import { Screenshot } from './types'

export default class WebOSScreenshotMixin implements IScreenshot {
  getScreenshotAsDataURL (): Promise<string> {
    return WebOSScreenshot
      .getScreenshotAsData()
      .then((result: Screenshot) => {
        return Promise.resolve(result.data)
      })
  }
}
