// @ts-ignore
const Signage = window.Signage

export default {
  /**
   * @see {@link http://webossignage.developer.lge.com/api/scap-api/scap16/signage/?wos_flag=OsdPortraitMode#OsdPortraitMode}
   */
  getOrientation (): Promise<string> {
    return new Promise((resolve, reject) => {
      const signage = new Signage()
      signage.getSignageInfo((result: {portraitMode: 'off' | 'on' | '90'}) => {
        resolve(result.portraitMode)
      }, reject)
    })
  },

  setPortraitMode (): Promise<void> {
    return new Promise((resolve, reject) => {
      const signage = new Signage()
      signage.setPortraitMode(resolve, reject, {
        portraitMode: Signage.OsdPortraitMode.ON
      })
    })
  },

  setLandscapeMode (): Promise<void> {
    return new Promise((resolve, reject) => {
      const signage = new Signage()
      signage.setPortraitMode(resolve, reject, {
        portraitMode: Signage.OsdPortraitMode.OFF
      })
    })
  }
}
