<template>
    <div class="iframe-container" ref="iframeContainer">
    </div>
</template>

<script>
import Vue from 'vue'
import { createIframeElement } from './ReachIframe'

/**
 * Component used to load Signs iframe
 */
export default Vue.extend({
  name: 'ReachIframe',
  props: {
    src: String,
    load: Boolean
  },
  data: function data () {
    return {
      source: this.src,
      initialize: this.load,
      iframeElement: null
    }
  },
  watch: {
    src: function source (value) {
      if (value) {
        this.iframeElement.src = value
      } else {
        this.iframeElement.src = null
      }
    },
    load: function load (value) {
      if (value === true) {
        this.loadIframe()
      }
    }

  },
  methods: {
    createIframeElement,
    loadIframe () {
      this.iframeElement.setAttribute('src', this.source)
    }
  },
  mounted () {
    this.iframeElement = createIframeElement('my-iframe', () => {
      console.log('REACHLog: Signs Iframe loaded')
    })
    const container = this.$refs.iframeContainer
    container.appendChild(this.iframeElement)
  }
})
</script>

<style lang="scss" scoped>
.iframe-container {
    width: 100%;
    height: 100%;
    background: white;
}
</style>
